import React from 'react';
import {
    Box,
    ExpandableSection,
    SpaceBetween,
    Table,
    Header,
} from "@amzn/awsui-components-react-v3";

interface Instruction {
    title: string;
    content: string;
}

interface ResultText {
    title: string;
    content: string;
}

interface ColumnSchema {
    name: string;
    required: boolean;
    description: string;
}

interface FileExampleRow {
    [key: string]: string;
}

interface Operation {
    operation: string;
    description: string;
}

interface OperationDescription {
    title: string;
    operations: Operation[];
}

interface ReadmeProps {
    maxRows?: number;
    instructions?: Instruction[];
    columnSchema?: ColumnSchema[];
    fileExample?: FileExampleRow[];
    outputExample?: FileExampleRow[];
    resultText?: ResultText;
    sampleFileLink?: React.ReactNode;
    validOperations?: {
        listBased: OperationDescription;
        jsonBased: OperationDescription;
    };
}

const schemaColumnDefinitions = [
    {
        id: "name",
        header: "Column Name",
        cell: (item: ColumnSchema) => item.name
    },
    {
        id: "required",
        header: "Is Required",
        cell: (item: ColumnSchema) => item.required ? "Yes" : "No"
    },
    {
        id: "description",
        header: "Description",
        cell: (item: ColumnSchema) => item.description
    },
];

const createColumnDefinitions = (columns: string[]) =>
    columns.map(column => ({
        id: column,
        header: column,
        cell: (item: FileExampleRow) => item[column],
    }));

export const PageReadme: React.FC<ReadmeProps> = ({
                                                      maxRows,
                                                      instructions,
                                                      columnSchema,
                                                      fileExample,
                                                      outputExample,
                                                      resultText,
                                                      sampleFileLink,
                                                      validOperations
                                                  }) => {
    const columns = React.useMemo(() =>
            fileExample && fileExample.length > 0 ? Object.keys(fileExample[0]) : [],
        [fileExample]
    );

    const outputColumns = React.useMemo(() =>
            outputExample && outputExample.length > 0 ? Object.keys(outputExample[0]) : [],
        [outputExample]
    );

    const exampleColumnDefinitions = React.useMemo(() =>
            createColumnDefinitions(columns),
        [columns]
    );

    const outputColumnDefinitions = React.useMemo(() =>
            createColumnDefinitions(outputColumns),
        [outputColumns]
    );

    return (
        <ExpandableSection
            header="GUIDELINES: How to use this page"
            data-testid="readme-expandable-section"
        >
            <SpaceBetween direction="vertical" size="l">
                {instructions && instructions.length > 0 && (
                    <Box>
                        <h3>Instructions</h3>
                        <ol>
                            {instructions.map((instruction, index) => (
                                <li key={index}>
                                    <span>
                                        <strong>{instruction.title}:</strong>
                                    </span>{" "}
                                    {instruction.content}
                                </li>
                            ))}
                        </ol>
                    </Box>
                )}

                {validOperations && (
                    <Box>
                        <h3>Valid Operations</h3>
                        <div style={{ marginBottom: '1rem' }}>
                            <h4>{validOperations.listBased.title}</h4>
                            <ul>
                                {validOperations.listBased.operations.map((op, index) => (
                                    <li key={index} style={{ whiteSpace: 'pre-line', marginBottom: '0.5rem' }}>
                                        <b>{op.operation}</b>: {op.description}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h4>{validOperations.jsonBased.title}</h4>
                            <ul>
                                {validOperations.jsonBased.operations.map((op, index) => (
                                    <li key={index} style={{ whiteSpace: 'pre-line', marginBottom: '0.5rem' }}>
                                        <b>{op.operation}</b>: {op.description}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Box>
                )}

                {columnSchema && columnSchema.length > 0 && (
                    <Box>
                        <Table
                            columnDefinitions={schemaColumnDefinitions}
                            items={columnSchema}
                            header={<Header>CSV File Schema</Header>}
                            data-testid="csv-schema-table"
                        />
                    </Box>
                )}

                {fileExample && fileExample.length > 0 && (
                    <Box>
                        <SpaceBetween direction="vertical" size="l">
                            <Table
                                columnDefinitions={exampleColumnDefinitions}
                                items={fileExample}
                                header={<Header>CSV File Example</Header>}
                                data-testid="csv-example-table"
                            />
                            {sampleFileLink && (
                                <Box padding={{top: "s"}}>
                                    {sampleFileLink}
                                </Box>
                            )}
                        </SpaceBetween>
                    </Box>
                )}

                {outputExample && outputExample.length > 0 && (
                    <Box>
                        <SpaceBetween direction="vertical" size="l">
                            {resultText && (
                                <Box padding={{left: "s"}}>
                                    <h4>{resultText.title}</h4>
                                    <p style={{whiteSpace: 'pre-line'}}>
                                        {resultText.content}
                                    </p>
                                </Box>
                            )}
                            <Table
                                columnDefinitions={outputColumnDefinitions}
                                items={outputExample}
                                header={<Header>Output CSV File Example</Header>}
                                data-testid="csv-output-example-table"
                            />
                        </SpaceBetween>
                    </Box>
                )}
            </SpaceBetween>
        </ExpandableSection>
    );
};