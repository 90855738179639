import React, {useEffect} from "react";
import {useSummaryDataWithAggregationKey} from "src/components/sm-overview-dashboards/summary-view/summary-utils";
import {productSummaryColumnDefinitions} from "src/components/sm-overview-dashboards/summary-view/summary-column-config";
import {SMOverviewAppLayout} from "src/components/sm-overview-dashboards/sm-overview-app-layout";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {QueryParams} from "src/components/sm-overview-dashboards/common/query-params";
import {useHistory} from "react-router-dom";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {
    getTimeRange
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {getTimeDiff} from "src/utils/sm-overview-dashboard-utils";
import {SummaryView} from "src/components/sm-overview-dashboards/summary-view/summary-components";

export const PRODUCT_SUMMARY_HEADER = 'SM Overview - Capability Health';
export const ProductSummaryView = () => {
    const params = new URLSearchParams(location.search);
    const filterQuery = params.get(QueryParams.FILTER_QUERY_KEY) || '';
    const history = useHistory();

    const {summaryData, loading, hasErrors}
        = useSummaryDataWithAggregationKey(filterQuery, DataStoreFields.PRODUCT);

    useEffect(() => {
        if(!filterQuery){
            const filterQueryParam = {
                [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${DataStoreFields.WORKFLOW_START_TIME}`]
                    : getTimeRange(getTimeDiff(3, 'MONTH'), 0)
            };
            params.append(QueryParams.FILTER_QUERY_KEY, JSON.stringify(filterQueryParam));
            history.replace({search: params.toString()});
        }
    }, [filterQuery])

    return <>
        <SummaryView
            summaryData={Object.values(summaryData)}
            columnDefinitions={productSummaryColumnDefinitions(filterQuery, DataStoreFields.PRODUCT)}
            loading={loading}
            hasErrors={hasErrors}
            viewName={metricFeaturesList.productSummary}
            headerName={PRODUCT_SUMMARY_HEADER}
        />
    </>
}

export const ProductSummary = () => <SMOverviewAppLayout
    content={<ProductSummaryView />}
    metricName={metricFeaturesList.productSummary}
    page={metricPageNames.programSummary}
/>
