import AppLayout from '@amzn/awsui-components-react-v3/polaris/app-layout';
import React, {useEffect, useState} from 'react';
import {appLayoutNavigationLabels} from 'src/common/labels';
import {ServiceNavigation} from 'src/components/navigation';
import 'src/styles/index.scss';
import {AppRoutes, BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {BreadcrumbGroupProps} from "@amzn/awsui-components-react-v3/polaris/breadcrumb-group/interfaces";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {Box, Container, Header, Tiles} from "@amzn/awsui-components-react-v3";
import {TilesProps} from "@amzn/awsui-components-react-v3/polaris/tiles/interfaces";
import {BulkRestartWorkflowInstance} from "src/components/restart-workflow-instance/bulk-restart-workflow-instance";

export default function RestartWorkflowInstance() {

    const bulkRestart: TilesProps.TilesDefinition = {
        value: 'bulk',
        label: 'Bulk Restart',
        description:
            'Use this option to restart multiple workflow instance using csv file.'
    }

    const items: ReadonlyArray<TilesProps.TilesDefinition> = [bulkRestart]

    const [selectedOption, setSelectedOption] = useState<string>(bulkRestart.value);
    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.restartWorkflowInstance)
    }, [])

    const bcItems: Array<BreadcrumbGroupProps.Item> = [
        {
            "text": "Home",
            "href": AppRoutes.home
        },
        {
            "text": "Workflow Instances",
            "href": AppRoutes.executionList
        },
        {
            "text": "Restart Workflow Instances",
            "href": "#"
        }
    ]

    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={<>
                <Container header={<Header variant={"h2"}>Please select</Header>}>
                    <Tiles
                        data-testid="restart-workflow-tiles"
                        items={items}
                        value={selectedOption}
                    />
                </Container>
                {selectedOption === bulkRestart.value && <Box><BulkRestartWorkflowInstance/></Box>}
            </>}
            breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
            contentType="form"
            disableContentPaddings={false}
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
            maxContentWidth={Number.MAX_VALUE}
        />
    );
}
