import SideNavigation, {SideNavigationProps} from "@amzn/awsui-components-react-v3/polaris/side-navigation";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {AppRoutes, RouteLabels} from "src/components/common/router-common";
// TODO: bring back the component
// import BreadcrumbGroup from '@amzn/awsui-components-react/polaris/breadcrumb-group';

const HOME = {text: "SWO", href: `#${AppRoutes.home}`};
const EXECUTION_LIST: SideNavigationProps.Item[] = [
    {type: "link", text: "Workflow Instance List", href: `#${AppRoutes.executionList}`},
]

const EXECUTION_SUMMARY: SideNavigationProps.Item[] = [
    {type: "link", text: "Aggregate Workflow Instance Summary", href: `#${AppRoutes.executionSummary}`}
]
const START_WI: SideNavigationProps.Item[] = [
    {type: "link", text: "Start Workflow Instance", href: `#${AppRoutes.startWorkflowInstance}`}
]

const RESTART_WI: SideNavigationProps.Item[] = [
    {type: "link", text: "Restart Workflow Instance", href: `#${AppRoutes.restartWorkflowInstance}`}
]

const MONITOR_WORKFLOWS: SideNavigationProps.Item[] = [
    {type: "link", text: "SM Capability Health", href: `#${AppRoutes.productSummary}`}
]

const EXECUTION_ITEMS: SideNavigationProps.Item[] = [...EXECUTION_LIST, ...START_WI, ...RESTART_WI, ...MONITOR_WORKFLOWS]


const EXECUTION_SECTION: SideNavigationProps.Item[] = [{
    type: "section", text: "Workflow",
    items: EXECUTION_ITEMS
}]

const PROGRAM_SOURCE_OPERATIONS: SideNavigationProps.Item[] = [
    { type: "link", text: "Bulk Fetch Records", href: `#${AppRoutes.ProgramSourceConfigStore}/fetch` },
    { type: "link", text: "Bulk Create Records", href: `#${AppRoutes.ProgramSourceConfigStore}/create` },
    { type: "link", text: "Bulk Update Records", href: `#${AppRoutes.ProgramSourceConfigStore}/update` },
];

const PROGRAM_SOURCE_SECTION: SideNavigationProps.Item[] = [
    {
        type: "section",
        text: "Program Source Repository",
        items: PROGRAM_SOURCE_OPERATIONS,
    },
];

const items: SideNavigationProps.Item[] = [{type: "link", text: "Home", href: `#${AppRoutes.home}`},
    ...EXECUTION_SECTION,
    ...PROGRAM_SOURCE_SECTION,
];

// Class ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
class ServiceNavigationLocal extends React.Component<RouteComponentProps> {
    // If the provided link is empty, do not redirect pages
    onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
        event.preventDefault();
        if (event.detail.external) {
            window.open(event.detail.href, "_blank")
            return;
        }
        
        this.props.history.push(event.detail.href.substring(1));
    }

    render() {
        return (
            <SideNavigation
                header={HOME}
                items={items}
                activeHref={`#${this.props.location.pathname}`}
                onFollow={this.onFollowHandler.bind(this)}
            />
        );
    }
}

export const ServiceNavigation = withRouter(ServiceNavigationLocal);
