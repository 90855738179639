export const mergeFiltersToFilterQuery = (filterQuery:string, newFilterParams: Record<string, string>) => {
    return filterQuery ? JSON.stringify(mergeFilters(JSON.parse(filterQuery), newFilterParams)) : JSON.stringify({});
}

export const mergeFilters = (filter: Record<string, string>, newFilterParams: Record<string, string>) => {
    return {...filter, ...newFilterParams};
}

export const buildFilterOptionsForProperty = (propertyKey: string, propertyValues: string[]) => {
    return propertyValues.map((propertyValue) => ({propertyKey: propertyKey, value: propertyValue}))
}
