import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {ElasticSearchHelper} from "src/utils/elastic-search-helper";
import {EXECUTION_LIST_DROPDOWN_OPTIONS} from "src/constants/domain-tracker";
import {getUserAlias} from "src/utils/cookie-helper";
import {unifiedDashboardAPIConfig} from "src/utils/api-gateway/swo-ud-api-mappings";
import {getStage} from "src/utils/env";
import {SMWorkflowOrchestrationServiceLambda} from "@amzn/sm-workflow-orchestration-service-js-client";
import {orchestratorServiceAPIConfig} from "src/utils/api-gateway/orchestrator-service-api-mappings";
import {APPLICATION_NAME} from "src/utils/app-constants";
import {
    AssignWorkflowInstanceRequest,
    AssignWorkflowInstanceRequestAssigneeString,
    AssignWorkflowInstanceRequestExecutionIdString, AssignWorkflowInstanceResult,
    BulkStartRecipeExecutionsRequest, BulkStartRecipeExecutionsResult,
    GetRecipeExecutionDetailsResult, GetWorkflowDetailsRequest, GetWorkflowDetailsResult, GetWorkflowVariantResponse,
    ObserveExecutionRequest,
    ObserveExecutionRequestAttemptIdString,
    ObserveExecutionRequestClientIdString,
    ObserveExecutionRequestExecutionIdString,
    ObserveExecutionRequestObservationIdString,
    ObserveExecutionRequestObservationTypeString,
    ObserveExecutionRequestObservationValueMap,
    ObserveExecutionRequestRequesterString,
    ObserveExecutionResult, RepublishDataBatchResponse, Resources,
    RestartRecipeExecutionRequestExecutionInputString, SaveWorkflowVariantRequest,
    StopRecipeExecutionResult
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {TableMetadataInterface} from "src/interfaces/server-side-table";
import {
    DataObjectType,
    GetWorkflowInstanceCardRequest,
    GetWorkflowInstanceCardResponse
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {EsQueryTableFilterI} from "src/interfaces/attribute-filters";
import {v1 as uuidv1} from 'uuid';
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricNamespace, metricPageNames} from "src/constants/swo-ui-metric-constants";
import {logger} from "src/utils/logger";
import AWS from "aws-sdk";
import {bulkRestartWorkflowInstanceS3Details, bulkStartWorkflowInstanceS3Details} from "src/utils/s3Util";

/**
 * Model to interact with SMSWOUnifiedDashboardServiceLambda API GW Client.
 */
export class DomainTracker {
    private unifiedDashboardServiceLambda: SMSWOUnifiedDashboardServiceLambda;
    private workflowOrchestrationServiceLambda: SMWorkflowOrchestrationServiceLambda;
    private orcServiceS3Util: AWS.S3;

    constructor() {

        const unifiedDashboardConfig = unifiedDashboardAPIConfig(getStage())
        const orchestratorServiceConfig = orchestratorServiceAPIConfig(getStage())
        this.unifiedDashboardServiceLambda = new SMSWOUnifiedDashboardServiceLambda({
            endpoint: `https://${unifiedDashboardConfig.endpoint}/${unifiedDashboardConfig.stage}`,
            region: unifiedDashboardConfig.region
        })
        this.workflowOrchestrationServiceLambda = new SMWorkflowOrchestrationServiceLambda({
            endpoint: `https://${orchestratorServiceConfig.endpoint}/${orchestratorServiceConfig.stage}`,
            region: orchestratorServiceConfig.region
        })
        this.orcServiceS3Util = new AWS.S3({});
    }

    /**
     * For ES Query using Table V3
     * @param esQueryTableFilter - Attribute filters
     * @param tableMetadata - Metadata about all columns
     * @param listCardName - Decides API to query
     */
    async searchV3(
        esQueryTableFilter: EsQueryTableFilterI,
        tableMetadata: TableMetadataInterface,
        listCardName: SMSWOUnifiedDashboardServiceLambda.Types.ListCard
    ): Promise<SMSWOUnifiedDashboardServiceLambda.Types.GetListCardResponse> {
        const esQuery = ElasticSearchHelper.convertAttributeFilterTokensToESQueryV3(esQueryTableFilter)
        console.log("Elastic search query v3 - ", esQuery)
        const getListCardRequest: SMSWOUnifiedDashboardServiceLambda.Types.GetListCardRequest = {
            listCardName: listCardName,
            queryString: JSON.stringify(esQuery),
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }
        const swoClientResp = await this.unifiedDashboardServiceLambda.getListCard(getListCardRequest).promise()
        return Promise.resolve(swoClientResp);
    }

    async restartExecution(clientId: string, executionId: string, lastAttemptId: string, requestID: string, requester: string, startPluginName: null | string,
                           executionInput?: RestartRecipeExecutionRequestExecutionInputString): Promise<SMWorkflowOrchestrationServiceLambda.Types.RestartRecipeExecutionResult> {
        const requestParam: SMWorkflowOrchestrationServiceLambda.Types.RestartRecipeExecutionRequest = {
            clientId: clientId,
            executionId: executionId,
            lastAttemptId: lastAttemptId,
            requestID: requestID,
            requester: requester,
            executionInput
        }
        if (startPluginName != null) {
            requestParam.startRecipeStep = startPluginName
        }

        logger.info(`Restarting workflow instance ${requestParam.executionId} with requestID ${requestParam.requestID}`, requestParam)
        const restartExecutionResponse = await this.workflowOrchestrationServiceLambda.restartRecipeExecution(requestParam).promise()
        logger.info(`Restarting workflow instance ${requestParam.executionId} with requestID ${requestParam.requestID} success`, requestParam)
        return restartExecutionResponse;
    }

    async terminateExecution(clientId: string, executionId: string, requestID: string, requester: string, reason: string): Promise<StopRecipeExecutionResult> {

        const requestParam: SMWorkflowOrchestrationServiceLambda.Types.StopRecipeExecutionRequest = {
            clientId: clientId,
            executionId: executionId,
            requestId: requestID,
            requester: requester,
            reason: reason
        }
        logger.info(`Terminating workflow instance ${requestParam.executionId} `, requestParam)
        const terminateExecutionResponse = await this.workflowOrchestrationServiceLambda.stopRecipeExecution(requestParam).promise()
        logger.info(`Terminating workflow instance ${requestParam.executionId} success`, requestParam)
        return terminateExecutionResponse;
    }

    /*
     * Retrieve workflow instance(execution) details
     */
    async getWorkflowInstance(workflowInstanceId: string): Promise<GetRecipeExecutionDetailsResult> {
        const workflowInstanceResponse = await this.workflowOrchestrationServiceLambda
            .getRecipeExecutionDetails({executionId: workflowInstanceId}).promise()
        return workflowInstanceResponse;
    }

    async observeExecution(executionId: ObserveExecutionRequestExecutionIdString,
                           clientId: ObserveExecutionRequestClientIdString,
                           attemptId: ObserveExecutionRequestAttemptIdString,
                           observationId: ObserveExecutionRequestObservationIdString,
                           observationType: ObserveExecutionRequestObservationTypeString,
                           observationValue: ObserveExecutionRequestObservationValueMap,
                           requester?: ObserveExecutionRequestRequesterString
    ): Promise<ObserveExecutionResult> {

        const observeRequest: ObserveExecutionRequest = {
            executionId,
            clientId,
            attemptId,
            observationId,
            observationType,
            observationValue,
            requester
        }
        logger.info(`Observe workflow ${executionId}`, observeRequest)
        const observeExecutionResp = await this.workflowOrchestrationServiceLambda.observeExecution(observeRequest).promise()
        logger.info(`Observe workflow ${executionId} success`, observeRequest)
        return observeExecutionResp;
    }

    async assignWorkflowInstance(executionId: AssignWorkflowInstanceRequestExecutionIdString,
                                 assignee: AssignWorkflowInstanceRequestAssigneeString
    ): Promise<AssignWorkflowInstanceResult> {

        const assignRequest: AssignWorkflowInstanceRequest = {
            executionId,
            assignee
        }
        logger.info(`Assign workflow ${executionId}`, assignRequest)
        const assignWorkflowResp = await this.workflowOrchestrationServiceLambda.assignWorkflowInstance(assignRequest).promise()
        logger.info(`Assign workflow ${executionId} success`, assignRequest)
        return assignWorkflowResp;
    }

    async startExecution(recipeExecutionInput: string) {
        const requestId = uuidv1();
        console.log("requestId - ", requestId)
        const bulkStartRecipeExecutionsRequest: BulkStartRecipeExecutionsRequest = {
            clientId: "SM",
            bulkStartInputS3Path: "s3://bulk-start-inputs-beta/bulkRequests/aravip_input_4.csv",
            // recipeId: "AravipTestACBPRecipe",
            recipeId: "ACBPRecipe",
            requestId: requestId,
            // recipeExecutionInput: recipeExecutionInput,
            requester: getUserAlias()
        }
        console.log("bulkStartRecipeExecutionsRequest", bulkStartRecipeExecutionsRequest)
        const bulkStartRecipeExecutionsResp = this.workflowOrchestrationServiceLambda
            .bulkStartRecipeExecutions(bulkStartRecipeExecutionsRequest).promise();
        bulkStartRecipeExecutionsResp;
    }

    async adhocStartWorkflow(clientId: string, recipeId: string, requester: string, program: string,
                             recipeExecutionInput: string, resources: Resources, subProgram?: string,
                             scheduleName?: string, scheduleExpression?: string, domain?: string,
                             targetedWebsite?: string, profileId?: string, websiteId?: string): Promise<BulkStartRecipeExecutionsResult> {
        const bulkStartRecipeExecutionsResp = await this.workflowOrchestrationServiceLambda
            .bulkStartRecipeExecutions({clientId, recipeId, requester, subProgram,
            recipeExecutionInput, program, resources, requestId: uuidv1(), scheduleName, scheduleExpression,
                domain, targetedWebsite, profileId, websiteId}).promise()

        return Promise.resolve(bulkStartRecipeExecutionsResp);

    }

    async bulkStartUploadS3(fileBlob: Blob): Promise<string> {
        const s3PathDetails = bulkStartWorkflowInstanceS3Details(getStage())
        const fileName = uuidv1()
        const s3Bucket = s3PathDetails.bucket
        const s3Key = `${s3PathDetails.key}${fileName}.csv`

        await this.orcServiceS3Util.putObject({
            Body: fileBlob,
            Bucket: s3Bucket,
            Key: s3Key,
            ACL: "bucket-owner-full-control"
        }).promise()

        return Promise.resolve(`s3://${s3Bucket}/${s3Key}`)
    }

    async bulkStartWorkflowInstance(s3Path: string, clientId: string, recipeId: string): Promise<BulkStartRecipeExecutionsResult> {

        const bulkStartRecipeExecutionsRequest: BulkStartRecipeExecutionsRequest = {
            clientId: clientId,
            bulkStartInputS3Path: s3Path,
            recipeId: recipeId,
            requester: getUserAlias()
        }

        console.log("bulkStartRecipeExecutionsRequest", bulkStartRecipeExecutionsRequest)
        const bulkStartRecipeExecutionsResp = this.workflowOrchestrationServiceLambda
            .bulkStartRecipeExecutions(bulkStartRecipeExecutionsRequest).promise();

        return Promise.resolve(bulkStartRecipeExecutionsResp)
    }

    async getWorkflowInstanceDetail(workflowInstanceId: string): Promise<GetWorkflowInstanceCardResponse> {
        const getWorkflowInstanceCardRequest: GetWorkflowInstanceCardRequest = {
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias(),
            workflowInstanceId: workflowInstanceId
        }

        const getWorkflowInstanceCardResponse = await this.unifiedDashboardServiceLambda
            .getWorkflowInstanceCard(getWorkflowInstanceCardRequest)
            .promise()
        return getWorkflowInstanceCardResponse;
    }

    async bulkRestartUploadS3(fileBlob: Blob, fileName: string, requestId: string): Promise<string> {
        const s3PathDetails = bulkRestartWorkflowInstanceS3Details(getStage());
        const currentDate = new Date();
        const dateString = `${currentDate.getFullYear()}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}`;
        const s3Bucket = s3PathDetails.bucket;
        const s3Key = `${s3PathDetails.key}date=${dateString}/requests/requestId=${requestId}/input/${fileName}`;

        await this.orcServiceS3Util.putObject({
            Body: fileBlob,
            Bucket: s3Bucket,
            Key: s3Key,
            ACL: "bucket-owner-full-control"
        }).promise();

        return Promise.resolve(`${s3Key}`);
    }

    async bulkRestartWorkflowInstance(s3Path: string, clientId: string, recipeId: string, requestId: string, program: string): Promise<BulkStartRecipeExecutionsResult> {
        const bulkStartRecipeExecutionsRequest: BulkStartRecipeExecutionsRequest = {
            clientId: clientId,
            recipeId: recipeId,
            program: program,
            requester: getUserAlias(),
            recipeExecutionInput: `{"SWO_BULK_RESTART": {"inputKey": "${s3Path}"}}`,
            resources: [{"resourceType": "SWO_RECIPE_EXECUTION", "units": 1}],
            requestId: requestId
        }

        const bulkRestartRecipeExecutionsResponse = await this.workflowOrchestrationServiceLambda
            .bulkStartRecipeExecutions(bulkStartRecipeExecutionsRequest).promise();

        return bulkRestartRecipeExecutionsResponse;
    }

    // TODO: Temporarily returning hardcoded values for v0 release, Should come from ES.
    // TODO: Should be async in nature.
    static searchAttributeValuesByKey(searchKey: string): Array<string> {
        return EXECUTION_LIST_DROPDOWN_OPTIONS[searchKey] || []
    }

    // TODO: Temporarily returning hardcoded values, Should come from ES.
    static async searchAttributeValuesByKeyAsync(searchKey: string): Promise<Array<string>> {
        await new Promise((res) => setTimeout(res, 1000)); // Just to mimic async behaviour.
        return Promise.resolve(EXECUTION_LIST_DROPDOWN_OPTIONS[searchKey] || [])
    }

    // Entire table is built based on this data.
    async getTableMetadata(dataObjectType: DataObjectType): Promise<TableMetadataInterface> {
        const metadata = await this.unifiedDashboardServiceLambda.getMetadata({
            dataObjectType: dataObjectType,
            requesterSystemType: APPLICATION_NAME,
            requesterUserName: getUserAlias()
        }).promise()
        // return Promise.resolve(dataObject == "WORKFLOW" ? executionListTableMetadata : executionAttemptTableMetadata)
        return Promise.resolve(metadata.dataObjectColumns);
    }

    async getWorkflowDetails(workflowId: string, workflowVersion?: string): Promise<GetWorkflowDetailsResult> {
        const getWorkflowInstanceCardResponse = await this.workflowOrchestrationServiceLambda.getWorkflowDetails({workflowId, workflowVersion}).promise()
        return Promise.resolve(getWorkflowInstanceCardResponse);
    }

    async getWorkflowVariant(program: string, subProgram: string) {
        return await this.workflowOrchestrationServiceLambda.getWorkflowVariant({workflowId: program, variantId: subProgram}).promise();
    }

    async saveProgramDefaults(saveDefaultRequest: SaveWorkflowVariantRequest) {
        return await this.workflowOrchestrationServiceLambda.saveWorkflowVariant(saveDefaultRequest).promise();
    }
}
