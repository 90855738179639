import React, {useState, useMemo, useCallback} from "react";
import {useMutation} from "@tanstack/react-query";
import {
    Box,
    Button,
    FormField,
    Input,
    Container,
    SpaceBetween,
    Modal,
    Header,
    Link,
} from "@amzn/awsui-components-react-v3";
import Select from "@amzn/awsui-components-react-v3/polaris/select";
import {toast} from "react-toastify";
import {unparse} from 'papaparse';
import {
    PROGRAM_SOURCE_CREATE_CSV_MAX_ROWS,
    SAMPLE_CREATE_RECORDS_INPUT,
    SAMPLE_CREATE_RECORDS_INPUT_FILE,
    CREATE_RECORDS_REQUIRED_COLUMNS,
    CREATE_README_INSTRUCTIONS,
    CREATE_README_COLUMN_SCHEMA,
    CREATE_FILE_EXAMPLE
} from "src/constants/program-source";
import {PageReadme} from "../common/page-readme";
import {ProgramSourceService} from "src/model/ProgramSourceService";
import {getUserAlias} from "src/utils/cookie-helper";
import {useProgramSource} from 'src/components/program-source-repository/program-source-util-hook';
import {parseCSVFile} from 'src/utils/csvUtils';

export function RegisterProgramSource() {
    const {
        programOptions,
        programSelectedOption,
        selectedFile,
        outputFileUrl,
        showConfirmation,
        setOutputFileUrl,
        setShowConfirmation,
        handleProgramChange,
        handleFileInput,
        handleSubmitClick,
        handleDownload,
        handleDownloadSample,
        isValid
    } = useProgramSource();

    const [loading, setLoading] = useState(false);
    /* istanbul ignore next */
    const uploadS3Mutation = useMutation({
        mutationFn: async (blob: Blob) => {
            const ps = new ProgramSourceService();
            return ps.ProgramSourceCreateUploadToS3(blob);
        },
        onError: (error) => {
            setLoading(false);
            toast.error(`S3 Upload failed: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
        }
    });
    /* istanbul ignore next */
    const createMutation = useMutation({
        mutationFn: async ({program, s3Bucket, s3Key, requester}: {
            program: string;
            s3Bucket: string;
            s3Key: string;
            requester: string;
        }) => {
            const ps = new ProgramSourceService();
            return ps.registerProgramSourcesFromS3(program, s3Bucket, s3Key, requester);
        },
        onMutate: () => {
            setOutputFileUrl(null);
        },
        onSuccess: (response) => {
            const {failedRequests, succeededRequests} = response;
            const downloadFileContent = [
                ["Failed Requests", "Succeeded Requests"],
                ...Array.from({length: Math.max(failedRequests.length, succeededRequests.length)}, (_, i) => [
                    failedRequests[i] || "",
                    succeededRequests[i] || "",
                ]),
            ];

            const csvContent = unparse(downloadFileContent);
            const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
            const url = URL.createObjectURL(blob);
            setOutputFileUrl(url);
            setLoading(false);
            toast.success("Request is processed. You can now download the results.");
        },
        onError: (error) => {
            setLoading(false);
            toast.error(`Operation failed: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
        }
    });

    const handleFileUpload = useCallback(
        async () => {
            try {
                const parseResult = await parseCSVFile(selectedFile!, {
                    maxRows: PROGRAM_SOURCE_CREATE_CSV_MAX_ROWS,
                    requiredColumns: CREATE_RECORDS_REQUIRED_COLUMNS
                });

                setLoading(true);

                const blob = new Blob([unparse(parseResult.data, {header: true})], {type: "text/csv"});
                const s3UploadResult = await uploadS3Mutation.mutateAsync(blob);

                createMutation.mutate({
                    program: programSelectedOption.value!,
                    s3Bucket: s3UploadResult.s3Bucket,
                    s3Key: s3UploadResult.s3Key,
                    requester: getUserAlias(),
                });
            } catch (err) {
                setLoading(false);
                toast.error(`${err instanceof Error ? err.message : String(err)}`);
            }
        },
        [selectedFile, programSelectedOption.value, createMutation]
    );

    const handleConfirm = useCallback(async () => {
        setShowConfirmation(false);
        handleFileUpload();
    }, [handleFileUpload]);

    const sampleFileLink = useMemo(
        () => (
            <Link
                href={""}
                onFollow={() => handleDownloadSample(SAMPLE_CREATE_RECORDS_INPUT, SAMPLE_CREATE_RECORDS_INPUT_FILE)}
            >
                Download sample CSV file
            </Link>
        ),
        [handleDownloadSample]
    );

    return (
        <Box>
            <Container header={<Header variant="h2">Bulk Create Records</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <form onSubmit={(e) => e.preventDefault()} data-testid="create-record-form">
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Select Program" data-testid="create-record-program-selection-field">
                                <Select
                                    data-testid="create-record-program-selection-dropdown"
                                    selectedOption={programSelectedOption}
                                    onChange={handleProgramChange}
                                    options={programOptions}
                                    selectedAriaLabel="Selected"
                                    disabled={loading}
                                />
                            </FormField>
                            <FormField label="Requester (auto populated)">
                                <Input
                                    value={getUserAlias()}
                                    disabled={true}
                                />
                            </FormField>
                            <FormField
                                label="Input File for Create (CSV)"
                                data-testid="form-field-file-input"
                                description={`Max ${PROGRAM_SOURCE_CREATE_CSV_MAX_ROWS} records`}
                            >
                                <input
                                    data-testid="create-record-file-input"
                                    type="file"
                                    onChange={handleFileInput}
                                    accept=".csv"
                                    disabled={loading}
                                />
                            </FormField>
                            <SpaceBetween direction="horizontal" size="m">
                                <Button
                                    variant="primary"
                                    formAction="submit"
                                    loading={loading}
                                    disabled={!isValid || loading}
                                    onClick={handleSubmitClick}
                                    data-testid="create-record-submit-button"
                                >
                                    Submit
                                </Button>
                                <Button
                                    onClick={handleDownload}
                                    disabled={!outputFileUrl}
                                    data-testid="download-record-submit-button"
                                >
                                    Download Results
                                </Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    </form>

                    <PageReadme
                        maxRows={PROGRAM_SOURCE_CREATE_CSV_MAX_ROWS}
                        instructions={CREATE_README_INSTRUCTIONS}
                        columnSchema={CREATE_README_COLUMN_SCHEMA}
                        fileExample={CREATE_FILE_EXAMPLE}
                        sampleFileLink={sampleFileLink}
                    />
                </SpaceBetween>

                <Modal
                    visible={showConfirmation}
                    onDismiss={() => setShowConfirmation(false)}
                    header="Confirm Creation"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => setShowConfirmation(false)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={handleConfirm}>
                                    Confirm
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    Are you sure you want to create these records?
                </Modal>
            </Container>
        </Box>
    );
}

