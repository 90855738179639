import React, {useState, useEffect, useMemo, useCallback} from "react";
import {Container, Header, Tiles, Box} from "@amzn/awsui-components-react-v3";
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import {ServiceNavigation} from "src/components/navigation";
import {BreadcrumbGroupWithRouter} from "src/components/common/router-common";
import {BreadcrumbGroupProps} from "@amzn/awsui-components-react-v3/polaris/breadcrumb-group/interfaces";
import {TilesProps} from "@amzn/awsui-components-react-v3/polaris/tiles/interfaces";
import {appLayoutNavigationLabels} from "src/common/labels";
import {GetProgramSource} from "./bulk-get-records";
import {RegisterProgramSource} from "./bulk-create-records";
import {UpdateProgramSource} from "./bulk-update-records";
import {useLocation, useHistory} from "react-router-dom";
import {AppRoutes} from "src/components/common/router-common";
import {SwoUiMetrics} from "src/utils/swo-ui-metrics/swo-ui-metrics";
import {metricFeaturesList, metricPageNames} from "src/constants/swo-ui-metric-constants";

export function ProgramSourceRepository() {
    const location = useLocation();
    const history = useHistory();
    const [selectedOperation, setSelectedOperation] = useState<string>("fetch");

    useEffect(() => {
        const path = location.pathname;
        if (path.endsWith("/fetch")) {
            setSelectedOperation("fetch");
        } else if (path.endsWith("/create")) {
            setSelectedOperation("create");
        } else if (path.endsWith("/update")) {
            setSelectedOperation("update");
        }
    }, [location]);

    const items: ReadonlyArray<TilesProps.TilesDefinition> = useMemo(
        () => [
            {
                value: "fetch",
                label: "Bulk Fetch Records",
                description: "Get latest configurations for selected existing Program-Sources",
            },
            {
                value: "create",
                label: "Bulk Create Records",
                description: "Register new Program-Sources",
            },
            {
                value: "update",
                label: "Bulk Update Records",
                description: "Update configurations for selected existing Program-Sources",
            },
        ],
        []
    );

    useEffect(() => {
        SwoUiMetrics.publishCloudWatchCountMetric(metricFeaturesList.index, metricPageNames.programSourceRepository)
    }, [])

    const bcItems: Array<BreadcrumbGroupProps.Item> = useMemo(
        () => [
            {text: "Home", href: "/"},
            {text: "Program Source Repository", href: "#"},
        ],
        []
    );

    const handleTileChange = useCallback(
        (e: { detail: { value: string } }) => {
            const newOperation = e.detail.value;
            setSelectedOperation(newOperation);
            history.push(`${AppRoutes.ProgramSourceConfigStore}/${newOperation}`);
        },
        [history]
    );

    const renderContent = useCallback(() => {
        switch (selectedOperation) {
            case "fetch":
                return <GetProgramSource data-testid="get-records-component"/>;
            case "create":
                return <RegisterProgramSource data-testid="create-records-component"/>;
            case "update":
                return <UpdateProgramSource data-testid="update-records-component"/>;
            default:
                return <p data-testid="default-message">Please select an operation.</p>;
        }
    }, [selectedOperation]);

    return (
        <AppLayout
            navigation={<ServiceNavigation/>}
            content={
                <Container header={<Header variant="h2">Program Source Repository</Header>}>
                    <Tiles
                        items={items}
                        value={selectedOperation}
                        onChange={handleTileChange}
                        data-testid="program-source-tiles"
                    />
                    <Box margin={{top: "l"}} data-testid="operation-content">
                        {renderContent()}
                    </Box>
                </Container>
            }
            breadcrumbs={<BreadcrumbGroupWithRouter items={bcItems}/>}
            contentType="form"
            toolsHide={true}
            ariaLabels={appLayoutNavigationLabels}
            maxContentWidth={Number.MAX_VALUE}
            data-testid="program-source-config-store"
        />
    );
}
