import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {START_TIME_MIN} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {
    StageRunningWorkflowDetail
} from "@amzn/swo-unified-dashboard-service-lambda-js-client/lib/smswounifieddashboardservicelambda";
import {getDaysDiffFromCurrentDateTimestamp} from "src/utils/date-helper";
import {getEpochTimestampForDaysAgo} from "src/utils/time-helper";

export const STAGE_SYS_ERROR_REGEX_MATCH = '(.*SYS_ERROR.*)&~(.*SWO.*)';
export const STAGE_APP_ERROR_REGEX_MATCH = '(.*APP_ERROR.*)&~(.*SWO.*)';
export const SWO_SYS_ERROR_REGEX_MATCH = '(.*SYS_ERROR.*)&(.*SWO.*)';
export const SWO_APP_ERROR_REGEX_MATCH = '(.*APP_ERROR.*)&(.*SWO.*)';
export const VALID_STAGE_ERROR_REGEX_MATCH = '.*(APP_ERROR|SYS_ERROR).*';
export const NOT_SWO_ERROR = '~(.*SWO.*)';
export const ALL_SWO_ERRORS = '.*SWO.*';
export const FAILED_STATE = 'FAILED';
export const STOPPED_STATE = 'STOPPED';
export enum TOTAL_KEY_NAMES {APP_ERROR_TOTAL = 'APP_ERROR_TOTAL' , SYS_ERROR_TOTAL =  'SYS_ERROR_TOTAL' , ERROR_TOTAL =  'ERROR_TOTAL'}
export const FIVE_DAY_OLD_RANGE_KEY = '5 days old';
export const ZERO_DAY_OLD_RANGE_KEY = '0 days old';
export const RANGE_DELIMITTER = ':'
export const timeDistributionRangeMap: Record<string, number> = {
    [FIVE_DAY_OLD_RANGE_KEY]: 5,
    [ZERO_DAY_OLD_RANGE_KEY]: 0
};
export const DEFAULT_WORKFLOW_RUN_CREATION_RANGE_FILTER = '1640995200000:1893456000000';

/**
 * Function is responsible for computing column totals for failure detail root view, The columns present Human errors,
 * System errors & All errors and their corresponding time ranges, the function builds a map with key pattern ErrorType-TimeRange
 * this map is used at render time in column config to map the values to correct column
 * @param failureDetailData - API response from server containing program Stages failure distribution
 */
export const getErrorColumnTotals = (failureDetailData: SMSWOUnifiedDashboardServiceLambda.ProgramFailureDetail[]) => {
    return failureDetailData.reduce((errorTotalMap: Record<string, number>, errorData: SMSWOUnifiedDashboardServiceLambda.ProgramFailureDetail) => {
        errorTotalMap[`${TOTAL_KEY_NAMES.APP_ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`] = (errorTotalMap[`${TOTAL_KEY_NAMES.APP_ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`] || 0) + (errorData.appErrorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0);
        errorTotalMap[`${TOTAL_KEY_NAMES.APP_ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`] = (errorTotalMap[`${TOTAL_KEY_NAMES.APP_ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`] || 0) + (errorData.appErrorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0);

        errorTotalMap[`${TOTAL_KEY_NAMES.SYS_ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`] = (errorTotalMap[`${TOTAL_KEY_NAMES.SYS_ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`] || 0) + (errorData.sysErrorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0);
        errorTotalMap[`${TOTAL_KEY_NAMES.SYS_ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`] = (errorTotalMap[`${TOTAL_KEY_NAMES.SYS_ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`] || 0) + (errorData.sysErrorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0);

        errorTotalMap[`${TOTAL_KEY_NAMES.ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`] = (errorTotalMap[`${TOTAL_KEY_NAMES.ERROR_TOTAL}-${ZERO_DAY_OLD_RANGE_KEY}`] || 0) + (errorData.errorCountDistributionBucket?.[ZERO_DAY_OLD_RANGE_KEY] || 0);
        errorTotalMap[`${TOTAL_KEY_NAMES.ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`] = (errorTotalMap[`${TOTAL_KEY_NAMES.ERROR_TOTAL}-${FIVE_DAY_OLD_RANGE_KEY}`] || 0) + (errorData.errorCountDistributionBucket?.[FIVE_DAY_OLD_RANGE_KEY] || 0);

        return errorTotalMap
    }, {} as Record<string, number>,)
}

/**
 * Function builds the timeRange payload used by page 1 Failure detail view to query Error metrics , App, SYS and all errors
 * with multiple time ranges (eg query App error for all time , 7 days old time etc). This function returns a stringified json
 * with keys corresponding to "timeDistributionRangeMap" and values being time ranges in pattern <startTime>:<endTime>
 */
export const getFailureViewTimeDistributionRanges = () => {
    return JSON.stringify(Object.keys(timeDistributionRangeMap).reduce((timeDistributionMap: Record<string, string>, timeRange:string) => {
         timeDistributionMap[`${timeRange}`]
            = getTimeRange(START_TIME_MIN, timeDistributionRangeMap[`${timeRange}`] || 0);
         return timeDistributionMap;
    }, {} as Record<string, string>))
}

export const getTimeRange = (timeStart: number, daysDiffFromCurrentDate: number) => {
    const earlierDateByTimeTimestamp = getDaysDiffFromCurrentDateTimestamp(daysDiffFromCurrentDate);
    return `${timeStart}:${earlierDateByTimeTimestamp}`;
}

export const RUNNING_WORKFLOW_AGE_DISTRIBUTION = {
    '0-10 days': '0:10',
    '10-30 days': '10:30',
    '30-50 days': '30:50',
    '> 50 days': '50:500'
}

export interface RunningWorkflowSummaryNode {
    nodeId: string;
    data: Record<string, number>;
    stage: string;
    parentId?: string;
    isROROpen?: boolean;
    isWorkflowNode?: boolean
}

export const transformRunningDataToTreeView = (runningData: StageRunningWorkflowDetail[], isWorkflowData: boolean) => {
    return runningData.reduce((runningWorkflowTreeCollection, runningStageData) => {
        const stage = isWorkflowData ? 'Running Workflows' : runningStageData.entity ?? '';
        const stageNode: RunningWorkflowSummaryNode = {
            nodeId: stage,
            stage: stage,
            data: runningStageData.totalWorkflowsCountDistributionBucket ?? {},
            isWorkflowNode: isWorkflowData
        };
        const rorOpenNode: RunningWorkflowSummaryNode = {
            nodeId: `${stage} with open ROR's`,
            parentId: stage,
            stage: stage,
            isROROpen: true,
            data: runningStageData.workflowsWithRorCountDistributionBucket ?? {},
            isWorkflowNode: isWorkflowData
        }
        const rorClosedNode: RunningWorkflowSummaryNode = {
            nodeId: `${stage} without open ROR's`,
            parentId: stage,
            stage: stage,
            isROROpen: false,
            data: runningStageData.workflowsWithoutRorCountDistributionBucket ?? {},
            isWorkflowNode: isWorkflowData
        }
        return [...runningWorkflowTreeCollection, stageNode, rorOpenNode, rorClosedNode];
    }, [] as Array<RunningWorkflowSummaryNode>)
}

export const transformDaysRangeToTimestamp = (rangeInDays: string) => {
    const ranges = rangeInDays.split(RANGE_DELIMITTER).map(range => parseInt(range));
    const rangeInTimestamp =
        `${getEpochTimestampForDaysAgo(ranges[1])}:${getEpochTimestampForDaysAgo(ranges[0])}`;
    return rangeInTimestamp;
}

export const transformDaysRangeToTimestampV2 = (filterQueryPayload: Record<string, string>, rangeInDays: string) => {
    // If workflow run creation range is not provided default to Jan 2022 to Jan 2030
    const workflowRunCreationRange = filterQueryPayload.hasOwnProperty("must::range:workflowruncreatetimestamp")
        ? filterQueryPayload["must::range:workflowruncreatetimestamp"] : DEFAULT_WORKFLOW_RUN_CREATION_RANGE_FILTER;
    const ranges = rangeInDays.split(RANGE_DELIMITTER).map(range => parseInt(range));
    const workflowRunCreationRangeDelimited = workflowRunCreationRange.split(RANGE_DELIMITTER).map(range => parseInt(range));
    const rangeInTimestamp =
        `${Math.max(getDaysDiffFromCurrentDateTimestamp(ranges[1]), workflowRunCreationRangeDelimited[0])}`+
        `:${Math.min(getDaysDiffFromCurrentDateTimestamp(ranges[0]), workflowRunCreationRangeDelimited[1])}`;
    return rangeInTimestamp;
}
