import React from "react";
import {Link, Popover, Icon} from "@amzn/awsui-components-react-v3";

export const HeaderCell: React.FunctionComponent<{ text: string, description: React.ReactNode, columnType: string }>
    = ({text, description, columnType}) => {
    return <>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100px',
            whiteSpace: 'normal'
        }}>
        <p style={{alignItems: 'center'}} data-testid={"header-cell-text"}>{text}</p>
        </div>
        <hr/>
        <p>
            <Popover
                dismissButton={true}
                position="top"
                size="small"
                triggerType="custom"
                content={description}
                header={columnType}
            >
                <Link variant="info">
                    <p>
                        <small style={{fontSize: '0.8em'}}> <a>Know more</a> </small>
                    </p>
                </Link>
            </Popover>
        </p>
    </>
}
