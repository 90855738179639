import {SMSWOUnifiedDashboardServiceLambda} from "@amzn/swo-unified-dashboard-service-lambda-js-client";
import {unifiedDashboardAPIConfig} from "src/utils/api-gateway/swo-ud-api-mappings";
import {getStage} from "src/utils/env";

export class LoadFieldValues {

    private unifiedDashboardServiceLambda: SMSWOUnifiedDashboardServiceLambda;

    constructor() {
        const unifiedDashboardConfig = unifiedDashboardAPIConfig(getStage());
        this.unifiedDashboardServiceLambda = new SMSWOUnifiedDashboardServiceLambda({
            endpoint: `https://${unifiedDashboardConfig.endpoint}/${unifiedDashboardConfig.stage}`,
            region: unifiedDashboardConfig.region,
        });
    }

    async loadDistinctFieldValuesFromIndex(field: string, index: string) {
        let loadFieldValuesRequest = {
            field: field,
            index: index
        };
        return this.unifiedDashboardServiceLambda.loadFieldValues(loadFieldValuesRequest).promise();
    }
}