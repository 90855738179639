import {useState, useCallback, useEffect} from 'react';
import {SelectProps} from "@amzn/awsui-components-react-v3/polaris/select/interfaces";
import {PROGRAM_V2} from "src/constants/program-source";
import {downloadSampleFile} from "src/utils/file-download-helper";
import {ButtonProps} from "@amzn/awsui-components-react-v3";

export const useProgramSource = () => {
    const pleaseSelectLabel: SelectProps.Option = {label: "Please Select", value: ""};
    const programOptions: Array<SelectProps.Option> = [
        pleaseSelectLabel,
        ...PROGRAM_V2.map((program) => ({
            label: program,
            value: program,
        })),
    ];

    const [programSelectedOption, setProgramSelectedOption] = useState<SelectProps.Option>(pleaseSelectLabel);
    const [selectedFile, setSelectedFile] = useState<null | Blob>(null);
    const [outputFileUrl, setOutputFileUrl] = useState<string | null>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleProgramChange = useCallback(({detail}: { detail: { selectedOption: SelectProps.Option } }) => {
        setProgramSelectedOption(detail.selectedOption);
    }, []);

    const handleFileInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        setSelectedFile(file);
    }, []);

    const handleSubmitClick: ButtonProps["onClick"] = useCallback((event) => {
        event.preventDefault();
        if (programSelectedOption.value && selectedFile) {
            setShowConfirmation(true);
        }
    }, [programSelectedOption.value, selectedFile]);

    const handleDownload = useCallback(() => {
        if (outputFileUrl) {
            window.open(outputFileUrl, "_blank");
        }
    }, [outputFileUrl]);

    const handleDownloadSample = useCallback((sampleFileName: string, downloadFileName: string) => {
        downloadSampleFile(sampleFileName, downloadFileName);
    }, []);

    useEffect(() => {
        if (outputFileUrl) {
            URL.revokeObjectURL(outputFileUrl);
            setOutputFileUrl(null);
        }
    }, [selectedFile]);

    return {
        programOptions,
        programSelectedOption,
        selectedFile,
        outputFileUrl,
        showConfirmation,

        setProgramSelectedOption,
        setSelectedFile,
        setOutputFileUrl,
        setShowConfirmation,

        handleProgramChange,
        handleFileInput,
        handleSubmitClick,
        handleDownload,
        handleDownloadSample,

        isValid: programSelectedOption.value !== "" && selectedFile !== null,
    };
};