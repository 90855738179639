import React, {useState, useCallback, useMemo} from "react";
import {useMutation} from "@tanstack/react-query";
import {
    Box,
    Button,
    FormField,
    Input,
    Container,
    SpaceBetween,
    Header,
    ButtonProps,
    Alert,
    Link
} from "@amzn/awsui-components-react-v3";
import {toast} from "react-toastify";
import {getUserAlias} from "src/utils/cookie-helper";
import {DomainTracker} from "src/model/DomainTracker";
import {parse, unparse} from 'papaparse';
import {v1 as uuidv1} from 'uuid';
import {
    BulkStartRecipeExecutionsResult
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
import {AppRoutes} from "src/components/common/router-common";
import {
    clientId,
    recipeId,
    program,
    BULK_RESTART_CSV_MAX_ROWS,
    SAMPLE_BULK_RESTART_RECORDS_INPUT,
    SAMPLE_BULK_RESTART_RECORDS_INPUT_FILE,
    BULK_RESTART_README_INSTRUCTIONS,
    BULK_RESTART_README_COLUMN_SCHEMA,
    BULK_RESTART_FILE_EXAMPLE,
    BULK_RESTART_OUTPUT_EXAMPLE, BULK_RESTART_RESULT_TEXT
} from "src/constants/restart-workflow-instance";
import {downloadSampleFile} from "src/utils/file-download-helper";
import {PageReadme} from "src/components/common/page-readme";

export function BulkRestartWorkflowInstance() {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [bulkRestartResult, setBulkRestartResult] = useState<BulkStartRecipeExecutionsResult>({
        successfulRequests: {},
        failedRequests: {}
    });

    const requestsId = uuidv1();
    /* istanbul ignore next */
    const bulkRestartMutation = useMutation({
        mutationFn: async (s3Path: string) => {
            const dt = new DomainTracker();
            return dt.bulkRestartWorkflowInstance(s3Path, clientId, recipeId, requestsId, program);
        },
        onSuccess: (response) => {
            setBulkRestartResult(response);
            const successCount = Object.keys(response.successfulRequests).length;
            const failureCount = Object.keys(response.failedRequests || {}).length;
            if (successCount > 0 && failureCount === 0) {
                toast.success("Request processed successfully");
            } else if (failureCount > 0) {
                toast.error(`Failed to restart workflows`);
            }
        },
        onError: (error) => {
            setBulkRestartResult(prev => ({
                ...prev,
                failedRequests: {
                    "error": error instanceof Error ? error.message : 'Unknown error occurred'
                }
            }));
            toast.error(`Bulk restart failed: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
        },
        onSettled: () => {
            setLoading(false);
        }
    });
    /* istanbul ignore next */
    const uploadS3Mutation = useMutation({
        mutationFn: async (blob: Blob) => {
            const dt = new DomainTracker();
            return dt.bulkRestartUploadS3(blob, selectedFile!.name, requestsId);
        },
        onSuccess: async (s3Path) => {
            await bulkRestartMutation.mutateAsync(s3Path);
        },
        onError: (error) => {
            setLoading(false);
            toast.error(`S3 Upload failed: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
        }
    });

    const handleFileInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setSelectedFile(file || null);
        setBulkRestartResult({successfulRequests: {}, failedRequests: {}});
    }, []);

    const handleDownloadFailedRequests = useCallback(() => {
        if (!bulkRestartResult.failedRequests) return;

        const failedContent = Object.entries(bulkRestartResult.failedRequests).map(([executionId]) => ({
            executionId
        }));

        const csvContent = unparse(failedContent);
        const fileName = "failed-restarts.csv";
        downloadSampleFile(csvContent, fileName);
    }, [bulkRestartResult.failedRequests]);

    const handleFileUpload = useCallback(async () => {
        if (!selectedFile) return;

        try {
            setLoading(true);
            parse(selectedFile, {
                header: true,
                skipEmptyLines: true,
                complete: async (parsingResults) => {
                    if (parsingResults.data.length === 0) {
                        setLoading(false);
                        toast.error("CSV File should have at least one row");
                        return;
                    }
                    if (parsingResults.data.length > BULK_RESTART_CSV_MAX_ROWS) {
                        setLoading(false);
                        toast.error(`Max ${BULK_RESTART_CSV_MAX_ROWS} rows allowed`)
                        return;
                    }
                    if (!parsingResults.meta.fields?.includes('executionId')) {
                        setLoading(false);
                        toast.error("CSV file must contain 'executionId' column");
                        return;
                    }

                    try {
                        const blob = new Blob([unparse(parsingResults.data)], {type: 'text/csv'});
                        await uploadS3Mutation.mutateAsync(blob);
                    } catch (error) {
                        setLoading(false);
                        toast.error(`Upload failed: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
                    }
                }
            });
        } catch (err) {
            setLoading(false);
            toast.error(`${err instanceof Error ? err.message : String(err)}`);
        }
    }, [selectedFile, uploadS3Mutation]);

    const handleSubmitClick: ButtonProps["onClick"] = useCallback((event) => {
        event.preventDefault();
        if (selectedFile) {
            handleFileUpload();
        }
    }, [selectedFile]);

    const handleDownloadSample = useCallback((sampleFileName: string, downloadFileName: string) => {
        try {
            downloadSampleFile(sampleFileName, downloadFileName);
        } catch (error) {
            toast.error(`Failed to download sample file: ${error instanceof Error ? error.message : 'Unknown error occurred'}`);
        }
    }, []);

    const sampleFileLink = useMemo(
        () => (
            <Link
                href={""}
                onFollow={() => handleDownloadSample(SAMPLE_BULK_RESTART_RECORDS_INPUT, SAMPLE_BULK_RESTART_RECORDS_INPUT_FILE)}
            >
                Download sample CSV file
            </Link>
        ),
        [handleDownloadSample]
    );

    return (
        <Box>
            <Box padding={{bottom: "l"}}>
                <Alert
                    visible={Object.entries(bulkRestartResult.successfulRequests).length > 0}
                    data-testid={"bulk-restart-success-alert"}
                    type={"success"}
                    dismissible={true}
                    onDismiss={() => setBulkRestartResult(prev => ({...prev, successfulRequests: {}}))}>
                    <SpaceBetween direction="vertical" size="s">
                        {Object.entries(bulkRestartResult.successfulRequests).map(([key, value]) => (
                            <div key={key}>
                                Successfully restarted Workflow Instance. View Instance detail
                                <Link data-testid={"view-execution-details"}
                                      href={AppRoutes.executionDetail(value)}> {value} </Link>
                            </div>
                        ))}
                    </SpaceBetween>
                </Alert>

                {bulkRestartResult.failedRequests &&
                    Object.keys(bulkRestartResult.failedRequests).length > 0 && (
                        <Alert
                            type="error"
                            data-testid="bulk-restart-error-alert"
                            dismissible={true}
                            onDismiss={() => setBulkRestartResult(prev => ({...prev, failedRequests: undefined}))}>
                            <SpaceBetween direction="vertical" size="s">
                                {Object.entries(bulkRestartResult.failedRequests).map(([key, value]) => (
                                    <p key={key}>{value}</p>
                                ))}
                                <Button
                                    onClick={handleDownloadFailedRequests}
                                    iconName="download"
                                    data-testid="download-failed-requests-button">
                                    Download Failed Requests
                                </Button>
                            </SpaceBetween>
                        </Alert>
                    )}
            </Box>

            <Container header={<Header variant="h2">Bulk Restart Workflow</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <form data-testid="restart-workflow-form">
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Requester (auto populated)">
                                <Input
                                    value={getUserAlias()}
                                    disabled={true}
                                />
                            </FormField>
                            <FormField
                                label="Input File (CSV)"
                                data-testid="form-field-file-input"
                                description={`Max ${BULK_RESTART_CSV_MAX_ROWS} records`}
                            >
                                <input
                                    data-testid="restart-workflow-file-input"
                                    type="file"
                                    onChange={handleFileInput}
                                    accept=".csv"
                                    disabled={loading}
                                />
                            </FormField>
                            <SpaceBetween direction="horizontal" size="m">
                                <Button
                                    variant="primary"
                                    formAction="submit"
                                    loading={loading}
                                    disabled={!selectedFile || loading}
                                    onClick={handleSubmitClick}
                                    data-testid="restart-workflow-submit-button"
                                >
                                    Submit
                                </Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    </form>
                    <PageReadme
                        maxRows={BULK_RESTART_CSV_MAX_ROWS}
                        instructions={BULK_RESTART_README_INSTRUCTIONS}
                        columnSchema={BULK_RESTART_README_COLUMN_SCHEMA}
                        fileExample={BULK_RESTART_FILE_EXAMPLE}
                        outputExample={BULK_RESTART_OUTPUT_EXAMPLE}
                        resultText={BULK_RESTART_RESULT_TEXT}
                        sampleFileLink={sampleFileLink}
                    />
                </SpaceBetween>
            </Container>
        </Box>
    );
}