import {getStage} from "src/utils/env";
import {v1 as uuidv1} from "uuid";
import AWS from "aws-sdk";
import {programSourceS3Details} from "src/utils/s3Util";
import {SMWorkflowOrchestrationServiceLambda} from "@amzn/sm-workflow-orchestration-service-js-client";
import {orchestratorServiceAPIConfig} from "src/utils/api-gateway/orchestrator-service-api-mappings";
import {PROGRAM_NAMES_ENUM, CREATE, UPDATE} from "src/constants/program-source";
import {
    UpdateProgramSourcesFromS3Response
} from "@amzn/sm-workflow-orchestration-service-js-client/lib/smworkfloworchestrationservicelambda";
interface ProgramSource {
    creationTimestamp: bigint;
    lastUpdationTimestamp: bigint;
    marketplaceIds: string[];
    program: PROGRAM_NAMES_ENUM;
    sciProductStrategy: string;
    sdoProductStrategy: string;
    sourceId: string;
    sourceName: string;
}

export class ProgramSourceService {
    private s3Client: AWS.S3;
    private workflowOrchestrationServiceLambda: SMWorkflowOrchestrationServiceLambda;

    constructor() {
        const orchestratorServiceConfig = orchestratorServiceAPIConfig(getStage());
        this.s3Client = new AWS.S3({});
        this.workflowOrchestrationServiceLambda = new SMWorkflowOrchestrationServiceLambda({
            endpoint: `https://${orchestratorServiceConfig.endpoint}/${orchestratorServiceConfig.stage}`,
            region: orchestratorServiceConfig.region,
        });
    }

    // Upload to s3 bucket For Create operation
    async ProgramSourceCreateUploadToS3(fileBlob: Blob): Promise<{ s3Path: string; s3Bucket: string; s3Key: string }> {
        return this.uploadToS3(fileBlob, CREATE);
    }

    // Upload to s3 bucket for update operation
    async ProgramSourceUpdateUploadToS3(fileBlob: Blob): Promise<{ s3Path: string; s3Bucket: string; s3Key: string }> {
        return this.uploadToS3(fileBlob, UPDATE);
    }

    async registerProgramSourcesFromS3(
        program: string,
        s3Bucket: string,
        s3ObjectKey: string,
        requester: string
    ): Promise<SMWorkflowOrchestrationServiceLambda.Types.RegisterProgramSourcesResponse> {
        const registerProgramSourcesFromS3Request: SMWorkflowOrchestrationServiceLambda.Types.RegisterProgramSourcesFromS3Request = {
            program,
            s3Bucket,
            s3ObjectKey,
            requester,
        };

        const registerProgramSourcesResponse = await this.workflowOrchestrationServiceLambda
            .registerProgramSourcesFromS3(registerProgramSourcesFromS3Request)
            .promise();

        return registerProgramSourcesResponse;
    }

    async updateProgramSourcesFromS3(
        program: string,
        s3Bucket: string,
        s3ObjectKey: string,
        requester: string
    ): Promise<SMWorkflowOrchestrationServiceLambda.Types.UpdateProgramSourcesFromS3Response> {
        const updateProgramSourcesFromS3Request: SMWorkflowOrchestrationServiceLambda.Types.UpdateProgramSourcesFromS3Request = {
            program,
            s3Bucket,
            s3ObjectKey,
            requester,
        };

        const updateProgramSourcesFromS3Response = await this.workflowOrchestrationServiceLambda
            .updateProgramSourcesFromS3(updateProgramSourcesFromS3Request)
            .promise();

        return updateProgramSourcesFromS3Response;
    }

    async getProgramSourcesByIdAndProgram(params: {
        programName: string;
        sourceIds: string[];
    }): Promise<{ programSources: ProgramSource[] }> {
        const GetProgramSourcesByIdAndProgramRequest: SMWorkflowOrchestrationServiceLambda.Types.GetProgramSourcesByIdAndProgramRequest = {
            program: params.programName,
            sourceIds: params.sourceIds,
        };

        try {
            const GetProgramSourcesByIdAndProgramResponse = await this.workflowOrchestrationServiceLambda
                .getProgramSourcesByIdAndProgram(GetProgramSourcesByIdAndProgramRequest)
                .promise();

            return GetProgramSourcesByIdAndProgramResponse as unknown as { programSources: ProgramSource[] };
        } catch (error) {
            console.error("Error in getProgramSourcesByIdAndProgram:", error);
            throw error;
        }
    }

    private async uploadToS3(
        fileBlob: Blob,
        operation: typeof CREATE | typeof UPDATE
    ): Promise<{
        s3Path: string;
        s3Bucket: string;
        s3Key: string;
    }> {
        const s3PathDetails = programSourceS3Details(getStage(), operation);
        const fileName = uuidv1();
        const s3Bucket = s3PathDetails.bucket;
        const s3Key = `${s3PathDetails.key}${fileName}.csv`;

        await this.s3Client
            .putObject({
                Body: fileBlob,
                Bucket: s3Bucket,
                Key: s3Key,
                ACL: "bucket-owner-full-control",
            })
            .promise();
        return {
            s3Path: `s3://${s3Bucket}/${s3Key}`,
            s3Bucket,
            s3Key,
        };
    }
}
