export const PROGRAM_SOURCE_CREATE_CSV_MAX_ROWS = 5000;
export const PROGRAM_SOURCE_GET_CSV_MAX_ROWS = 5000;
export const PROGRAM_SOURCE_UPDATE_CSV_MAX_ROWS = 5000;
export const CREATE = "create";
export const UPDATE = "update";

export enum PROGRAM_NAMES_ENUM {
    SELECTION_GAP_CLOSURE = "SelectionGapClosure",
    STARFISH = "Starfish",
    SOURCE_INSIGHTS = "SourceInsights",
}

export const PROGRAM_V2 = [
    PROGRAM_NAMES_ENUM.SELECTION_GAP_CLOSURE,
    PROGRAM_NAMES_ENUM.STARFISH,
    PROGRAM_NAMES_ENUM.SOURCE_INSIGHTS,
];

// Readme
export const CREATE_README_INSTRUCTIONS = [
    {
        title: "Select a Program",
        content: "Choose the program for which you want to register sources. Note that each request is limited to registering sources for a single program."
    },
    {
        title: "Upload a CSV File",
        content: "Upload an un-encoded CSV file that contains all the necessary configurations."
    },
    {
        title: "Download the Outcome",
        content: "Once the processing is complete, you will be able to download the output file. This file will list the sourceIds that were successfully registered as well as those that could not be registered."
    },
    {
        title: "Unique Source IDs",
        content: "Ensure that a single CSV file does not contain the same sourceId more than once."
    },
    {
        title: "Follow the JSON Schema",
        content: "Ensure that your request follows the Json schema. Failure to do so will result in the particular sourceId being discarded."
    },
    {
        title: "Re-registration",
        content: "Re-registration attempts of an existing program-source will be rejected. Use Bulk Update Records Component for any updates."
    },
    {
        title: "Registration of new Records",
        content: "Registration of new records is only supported by the Bulk Create Records Component only."
    }
];

export const CREATE_README_COLUMN_SCHEMA = [
    {name: "sourceId", required: true, description: "Unique identifier for the source"},
    {name: "sourceName", required: true, description: "Name of the source"},
    {
        name: "marketplaceIds",
        required: false,
        description: "Id's of marketplaces associated with the source (comma-separated list of Id's)"
    },
    {name: "sdoProductStrategy", required: false, description: "SDO config for data delivery (Json)"},
    {name: "sciProductStrategy", required: false, description: "SCI config for data delivery (Json)"}
];

export const CREATE_FILE_EXAMPLE = [
    {
        sourceId: "1072681",
        sourceName: "nike.com",
        marketplaceIds: "44511,1",
        sdoProductStrategy: '{"grainOfExtraction":"detail"}',
        sciProductStrategy: '{"targetAmazonMarketplaceIds":[],"applications":["EmBHS"],"isSourceQualified":true,"matching":{"model":"epm1dot1"},"clustering":{"dedupeRequired":false}}'
    },
    {
        sourceId: "1012201",
        sourceName: "walmart.com",
        marketplaceIds: "153490",
        sdoProductStrategy: "",
        sciProductStrategy: ""
    }
];

export const GET_README_INSTRUCTIONS = [
    {
        title: "Select a Program",
        content: "Choose the program for which you want to fetch sources. Note that each request is limited to fetching sources for a single program."
    },
    {
        title: "Upload a CSV File",
        content: `Upload an un-encoded CSV file that contains the sourceIds. Ensure that the number of records in the file does not exceed ${PROGRAM_SOURCE_GET_CSV_MAX_ROWS}.`
    },
    {
        title: "Download the Outcome",
        content: "Once the processing is complete, you will be able to download the output file. This file will contain the configurations of the existing sourceIds from the provided list."
    }
];

export const GET_README_COLUMN_SCHEMA = [
    {name: "sourceIds", required: true, description: "Unique identifier for the source"}
];

export const GET_FILE_EXAMPLE = [
    {sourceIds: "1072681"},
    {sourceIds: "1001101"}
];
export const UPDATE_README_INSTRUCTIONS = [
    {
        title: "Select a Program",
        content: "Choose the program for which you want to update sources.Note that each request is limited to updating sources for a single program."
    },
    {
        title: "Upload a CSV File",
        content: "Upload an unencoded CSV file that contains the configurations that need to be updated."
    },
    {
        title: "Field Updates",
        content: "The update functionality supports updating one or more fields for each record simultaneously. To update a field, provide its corresponding operation field with a valid value. For \"sourceName\", which doesn't have an operation field, simply leave it empty if no modification is needed."
    },
    {
        title: "Download the Outcome",
        content: "Once the processing is complete, you will be able to download the output file. This file will list the sourceIds that were successfully updated as well as those that could not be updated."
    },
    {
        title: "Unique Source IDs",
        content: "Ensure that a single CSV file does not contain the same sourceId more than once. Multiple fields can be updated in the same row for a given sourceId."
    },
    {
        title: "Follow the JSON Schema",
        content: "Ensure that your request follows the Json schema. Failure to do so will result in the particular sourceId being discarded."
    },
    {
        title: "Default Operation Behavior",
        content: "If the operation coulumn is not specified or is invalid for any field for a record, the field won't be updated for that record."
    },
    {
        title: "Registration of new Records",
        content: "Registration of new records is only supported by the Bulk Create Records Component only."
    }
];

export const VALID_OPERATIONS_DESCRIPTION = {
    listBased: {
        title: "Valid List-Based Operations (for marketplaceIds):",
        operations: [
            {
                operation: "upsert",
                description: "Does nothing if an element to be added already exists, otherwise inserts it in the list."
            },
            {
                operation: "overwrite",
                description: "User input completely overwrites the existing value."
            }
        ]
    },
    jsonBased: {
        title: "Valid JSON-Based Operations (for sdoProductStrategy and sciProductStrategy):",
        operations: [
            {
                operation: "deepMerge",
                description: "A deep merge operation combines two JSON objects recursively. It traverses through all nested objects and arrays, merging their contents. When a key exists in both objects, the value from the second object overwrites the first, unless both values are objects, in which case they are merged recursively. This process ensures that nested structures are preserved and combined, rather than being overwritten entirely."
            },
            {
                operation: "plainMerge",
                description: "A plain merge operation combines two JSON objects by overwriting fields in the original object with those provided in the input object. This includes both top-level and nested fields. If a field exists in the input, it completely replaces the corresponding field in the original object, regardless of whether the field contains a nested object or a simple value. If it does not exist in the original object it is added."
            },
            {
                operation: "overwrite",
                description: "User input completely overwrites the existing value."
            }
        ]
    }
};

export const UPDATE_README_COLUMN_SCHEMA = [
    {
        name: "sourceId",
        required: true,
        description: "Unique identifier for the source"
    },
    {
        name: "sourceName",
        required: false,
        description: "Name of the source. Keep empty if this is not intended to be updated"
    },
    {
        name: "marketplaceIds",
        required: false,
        description: "Comma separated list of marketplace IDs"
    },
    {
        name: "sdoProductStrategy",
        required: false,
        description: "SDO config for data delivery (Json)"
    },
    {
        name: "sciProductStrategy",
        required: false,
        description: "SCI config for data delivery (Json)"
    },
    {
        name: "marketplaceIdsOperation",
        required: false,
        description: "Operation type for marketplaceIds. See Valid List-Based Operations above"
    },
    {
        name: "sdoProductStrategyOperation",
        required: false,
        description: "Operation type for SDO strategy. See Valid JSON-Based Operations above"
    },
    {
        name: "sciProductStrategyOperation",
        required: false,
        description: "Operation type for SCI strategy. See Valid JSON-Based Operations above"
    }
];

export const UPDATE_FILE_EXAMPLE = [
    {
        sourceId: "1",
        sourceName: "mouser.com",
        marketplaceIds: "1",
        sdoProductStrategy: "",
        sciProductStrategy: "",
        marketplaceIdsOperation: "",
        sdoProductStrategyOperation: "",
        sciProductStrategyOperation: ""
    },
    {
        sourceId: "2",
        sourceName: "www.monotaroTest.com",
        marketplaceIds: "2,4",
        sdoProductStrategy: '{"grainOfExtraction":"browse"}',
        sciProductStrategy: '{"targetAmazonMarketplaceIds":[],"applications":["EmBHS"],"isSourceQualified":false,"matching":{"model":"epm1dot1"},"clustering":{"dedupeRequired":true},"test":true}',
        marketplaceIdsOperation: "upsert",
        sdoProductStrategyOperation: "plainMerge",
        sciProductStrategyOperation: "plainMerge"
    },
    {
        sourceId: "3",
        sourceName: "gfk_feed.com",
        marketplaceIds: "1,2,3",
        sdoProductStrategy: '{"grainOfExtraction":"detail","customField":"value"}',
        sciProductStrategy: '{"targetAmazonMarketplaceIds":["A1F83G8C2ARO7P"],"applications":["EmBHS","CatalogIngestion"],"isSourceQualified":true}',
        marketplaceIdsOperation: "overwrite",
        sdoProductStrategyOperation: "deepMerge",
        sciProductStrategyOperation: "overwrite"
    },
    {
        sourceId: "4",
        sourceName: "",
        marketplaceIds: "5,6",
        sdoProductStrategy: '{"grainOfExtraction":"browse","newField":{"subField":"value"}}',
        sciProductStrategy: "",
        marketplaceIdsOperation: "upsert",
        sdoProductStrategyOperation: "deepMerge",
        sciProductStrategyOperation: ""
    },
    {
        sourceId: "5",
        sourceName: "supplier_direct.com",
        marketplaceIds: "",
        sdoProductStrategy: '{"settings":{"timeout":30}}',
        sciProductStrategy: '{"matching":{"model":"epm2dot0"},"clustering":{"dedupeRequired":false}}',
        marketplaceIdsOperation: "",
        sdoProductStrategyOperation: "plainMerge",
        sciProductStrategyOperation: "deepMerge"
    }
];

// Sample csv file name
export const SAMPLE_FETCH_RECORDS_INPUT_FILE = "sample_fetch_records_input.csv";
export const SAMPLE_CREATE_RECORDS_INPUT_FILE = "sample_create_records_input.csv";
export const SAMPLE_UPDATE_RECORDS_INPUT_FILE = "sample_update_records_input.csv";

// Sample csv files
export const SAMPLE_CREATE_RECORDS_INPUT = `sourceId,sourceName,marketplaceIds,sdoProductStrategy,sciProductStrategy
1072681,nike.com,"44511,1","{""grainOfExtraction"":""detail""}","{""targetAmazonMarketplaceIds"":[],""applications"":[""EmBHS""],""isSourceQualified"":true,""matching"":{""model"":""epm1dot1""},""clustering"":{""dedupeRequired"":false}}"
1012201,walmart.com,"153490"`;

export const SAMPLE_FETCH_RECORDS_INPUT = `sourceIds
1072681
1001101`;

export const SAMPLE_UPDATE_RECORDS_INPUT = `sourceId,sourceName,marketplaceIds,sdoProductStrategy,sciProductStrategy,marketplaceIdsOperation,sdoProductStrategyOperation,sciProductStrategyOperation
1,mouser.com,1,,,,,
2,www.monotaroTest.com,"2,4","{""grainOfExtraction"":""browse""}","{""targetAmazonMarketplaceIds"":[],""applications"":[""EmBHS""],""isSourceQualified"":false,""matching"":{""model"":""epm1dot1""},""clustering"":{""dedupeRequired"":true},""test"":true}",upsert,plainMerge,plainMerge
3,gfk_feed.com,"1,2,3","{""grainOfExtraction"":""detail"",""customField"":""value""}","{""targetAmazonMarketplaceIds"":[""A1F83G8C2ARO7P""],""applications"":[""EmBHS"",""CatalogIngestion""],""isSourceQualified"":true}",overwrite,deepMerge,overwrite
4,,"5,6","{""grainOfExtraction"":""browse"",""newField"":{""subField"":""value""}}","",upsert,deepMerge,
5,supplier_direct.com,"","{""settings"":{""timeout"":30}}","{""matching"":{""model"":""epm2dot0""},""clustering"":{""dedupeRequired"":false}}",,plainMerge,deepMerge`;

// Required columns
export const GET_RECORDS_REQUIRED_COLUMNS = ['sourceIds'] as const;
export const CREATE_RECORDS_REQUIRED_COLUMNS = ['sourceId', 'sourceName'] as const;
export const UPDATE_RECORDS_REQUIRED_COLUMNS = ['sourceId'] as const;