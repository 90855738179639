/**
 * Formats a date object to a date string in YYYY-MM-DD format
 * @param {Date} date - The date object to format
 * @return {string} The formatted date string
 */
export const formatDateFromDate = (date: Date): string => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}

/**
 * Formats a date object to a time string in HH:MM format
 * @param {Date} date - The date object to format
 * @return {string} The formatted time string
 */
export const formatTimeFromDate = (date: Date): string => {
    return date.toTimeString().slice(0, 5);
}

export const getDaysDiffFromCurrentDateTimestamp = (daysDiffFromCurrentDate: number) => {
    const earlierDateByTimeDiff = new Date();
    earlierDateByTimeDiff.setDate(earlierDateByTimeDiff.getDate() - daysDiffFromCurrentDate);
    earlierDateByTimeDiff.setHours(23,59,59,999);
    return earlierDateByTimeDiff.getTime();
}