/* istanbul ignore file */
import {Calendar, DateInput, FormField, Input, TimeInput, PropertyFilterProps, SpaceBetween} from "@amzn/awsui-components-react-v3";
import React from "react";
import {PropertyFilterOperatorForm} from "@amzn/awsui-collection-hooks";
import {getMultiselectForm} from "src/components/sm-overview-dashboards/workflow-list-view/filters/multi-select-form";

export enum DataStoreFields {
    WORKFLOW_CREATE_TIMESTAMP = 'workflowcreatetimestamp',
    STAGE_CREATION_TIMESTAMP = 'stagecreationtimestamp',
    STAGE_LAST_UPDATE_TIMESTAMP = 'stagelastupdatetimestamp',
    STAGE_ERROR = 'stageerror',
    STAGE_CAUSE = 'stagecause',
    STAGE_NAME = 'stagename',
    STAGE_STATUS = 'stagestatus',
    PROGRAM = 'program',
    PRODUCT = 'product',
    WORKFLOW_STATUS = 'state',
    ERROR = 'error',
    SUB_PROGRAM = 'subprogram',
    MARKET_PLACE = 'targetedwebsite',
    FAILED_WORKFLOW_TYPE = 'failuretype',
    IS_ROR_OPEN = 'isRoROpen-Number',
    DOMAIN = 'domain',
    ATTEMPT_LAST_UPDATE_TIMESTAMP = 'attemptlastupdatetimestamp',
    WORKFLOW_START_TIME = 'workflowruncreatetimestamp',
    WORKFLOW_END_TIME = 'workflowrunlastupdatetimestamp',
    WORKFLOW_LAST_UPDATE_TIMESTAMP = 'workflowlastupdatetimestamp',
    USE_CASE = "usecase",
    VARIANT = "variant",
    BUSINESS_PROGRAM = 'associatedprograms',
    HEAD_STAGE = 'headstage',
    HEAD_STAGE_STATUS = 'headstagestatus',
    DAYS_SINCE_HEAD_STAGE = 'executingindependentlysince',
}

export enum FilterOperation {
    MATCH = 'match',
    MATCH_ANY = 'matchAny',
    RANGE = 'range',
    LIKE = 'like',
    REGEX = 'regex'
}

export enum QueryVerb {
    MUST = 'must',
    MUST_NOT = 'mustNot',
    FILTER = 'filter'
}

export const START_TIME_MIN = 1656683226000;
export const END_TIME_MAX = 2609498495000;
export const MIN_DAYS_SINCE_HEAD_STAGE = 0;
export const MAX_DAYS_SINCE_HEAD_STAGE = 1000;
export const TIME_ELAPSED_FIELD_NAMES: ReadonlyArray<String> = [DataStoreFields.DAYS_SINCE_HEAD_STAGE];

export enum FilterTokenOperators {
    EQUAL = '=',
    NOT_EQUAL = '!=',
    REGEX = ':',
    GREATER_THAN_EQUAL = '>=',
    LESS_THAN_EQUAL = '<=',
    MATCH = '^'
}

export enum FilterPropertyGroups {
    TIME_FILTERS = 'Time Filters',
    MULTI_SELECT_FILTERS = 'Multiselect filters',
    SELECT_FILTERS = 'Select filters',
    LEGACY_MULTISELECT_FILTERS = 'Legacy filters'
}

export const getDateForm = () => ({ value, onChange }: { value: string, onChange: any }) => {
    const [dateValue, timeValue] = value ? value.split(' ') : ["", ""];

    const handleDateChange = (newDate: string) => {
        const combinedValue = newDate ? `${newDate} ${timeValue}` : "";
        onChange(combinedValue);
    };

    const handleTimeChange = (newTime: string) => {
        const combinedValue = dateValue ? `${dateValue} ${newTime}` : "";
        onChange(combinedValue);
    };

    return (
        <div className="date-form">
            <FormField>
                <DateInput
                    value={dateValue}
                    onChange={event => handleDateChange(event.detail.value)}
                    placeholder="YYYY/MM/DD"
                />
            </FormField>
            <FormField>
                <TimeInput
                    value={timeValue}
                    onChange={event => handleTimeChange(event.detail.value)}
                    format="hh:mm"
                    placeholder="HH:MM"
                />
            </FormField>
            <Calendar
                value={dateValue ?? ""}
                onChange={event => handleDateChange(event.detail.value)}
                locale="en-GB"
                todayAriaLabel="Today"
                nextMonthAriaLabel="Next month"
                previousMonthAriaLabel="Previous month"
                isDateEnabled={(date) => date.getTime() >= START_TIME_MIN}
            />
        </div>
    );
};


export const getNonNegativeFloatForm = ({ value, onChange }: { value: string, onChange: (value: string) => void }) => {
    const handleChange = (event: any) => {
        const inputValue = event.detail.value;
        // Allow only non-negative decimals
        if (/^\d*\.?\d*$/.test(inputValue)) {
            onChange(inputValue); // Update value only if valid
        }
    };

    return (
        <FormField>
            <Input
                type="text"
                value={value}
                onChange={handleChange}
                placeholder="Enter a positive decimal"
            />
        </FormField>
    );
};

export const getRegexForm = (): PropertyFilterOperatorForm<string> =>
    ({value, onChange}) => {
        const [regexValue, setRegexValue] = React.useState(value || '');

        return (
            <div style={{height: '120px', minWidth: '50px', maxWidth: '200px'}}>
                <FormField
                    stretch
                    description="Enter a regex pattern. If plain string is provided, then it'll be converted to regex"
                >
                    <SpaceBetween size="xs">
                        <Input
                            value={regexValue}
                            onChange={({ detail }) => {
                                setRegexValue(detail.value);
                                onChange(detail.value);
                            }}
                            autoComplete="off"
                        />
                    </SpaceBetween>
                </FormField>
            </div>
        );
    };

export const filterPropertyConfigMap: Record<string, PropertyFilterProps.FilteringProperty> = {
    [DataStoreFields.BUSINESS_PROGRAM] : {
        key: DataStoreFields.BUSINESS_PROGRAM,
        propertyLabel: 'Program name',
        groupValuesLabel: "Select Program",
        operators: [FilterTokenOperators.EQUAL],
        group: FilterPropertyGroups.SELECT_FILTERS
    },
    [DataStoreFields.WORKFLOW_STATUS] : {
        key: DataStoreFields.WORKFLOW_STATUS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form: getMultiselectForm(DataStoreFields.WORKFLOW_STATUS),
            format: values => values
        })),
        propertyLabel: 'Workflow status',
        groupValuesLabel: "Workflow status's"
    },
    [DataStoreFields.HEAD_STAGE_STATUS] : {
        key: DataStoreFields.HEAD_STAGE_STATUS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Head stage status',
        groupValuesLabel: "Head stage status's"
    },
    [DataStoreFields.STAGE_NAME] : {
        key: DataStoreFields.STAGE_NAME,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form: getMultiselectForm(DataStoreFields.STAGE_NAME),
            format: values => values
        })),
        propertyLabel: 'Stage',
        groupValuesLabel: "Stage's"
    },
    [DataStoreFields.HEAD_STAGE] : {
        key: DataStoreFields.HEAD_STAGE,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Head stage',
        groupValuesLabel: "Head stage's"
    },
    [DataStoreFields.DOMAIN] : {
        key: DataStoreFields.DOMAIN,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Domain',
        groupValuesLabel: "Domain's"
    },
    [DataStoreFields.STAGE_ERROR] : {
        key: DataStoreFields.STAGE_ERROR,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL, {
            operator: FilterTokenOperators.REGEX,
            form: getRegexForm(),
            format: value => value
        }],
        propertyLabel: 'Error',
        groupValuesLabel: "Error's"
    },
    [DataStoreFields.ERROR] : {
        key: DataStoreFields.ERROR,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL, {
            operator: FilterTokenOperators.REGEX,
            form: getRegexForm(),
            format: value => value
        }],
        propertyLabel: 'Workflow Error',
        groupValuesLabel: "Workflow Error's"
    },
    [DataStoreFields.STAGE_CAUSE] : {
        key: DataStoreFields.STAGE_CAUSE,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL, {
            operator: FilterTokenOperators.REGEX,
            form: getRegexForm(),
            format: value => value
        }],
        propertyLabel: 'Error Cause',
        groupValuesLabel: "Error Causes"
    },
    [DataStoreFields.STAGE_STATUS]: {
        key: DataStoreFields.STAGE_STATUS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL],
        propertyLabel: 'Stage Status',
        groupValuesLabel: "Stage Status's"
    },
    [DataStoreFields.MARKET_PLACE]: {
        key: DataStoreFields.MARKET_PLACE,
        propertyLabel: 'Market Place',
        groupValuesLabel: "Market Places's",
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form: getMultiselectForm(DataStoreFields.MARKET_PLACE),
            format: values => values
        }))
    },
    [DataStoreFields.WORKFLOW_CREATE_TIMESTAMP]:                 {
        key: DataStoreFields.WORKFLOW_CREATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow creation time",
        groupValuesLabel: "Workflow creation times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.STAGE_LAST_UPDATE_TIMESTAMP]:  {
        key: DataStoreFields.STAGE_LAST_UPDATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Stage last update time",
        groupValuesLabel: "Stage last update times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.ATTEMPT_LAST_UPDATE_TIMESTAMP]:  {
        key: DataStoreFields.ATTEMPT_LAST_UPDATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Attempt last update time",
        groupValuesLabel: "Attempt last update times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.WORKFLOW_LAST_UPDATE_TIMESTAMP]:  {
        key: DataStoreFields.WORKFLOW_LAST_UPDATE_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow last update time",
        groupValuesLabel: "Workflow last update times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.STAGE_CREATION_TIMESTAMP]:  {
        key: DataStoreFields.STAGE_CREATION_TIMESTAMP,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Stage creation time",
        groupValuesLabel: "Stage creation times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.FAILED_WORKFLOW_TYPE]: {
        key: DataStoreFields.FAILED_WORKFLOW_TYPE,
        propertyLabel: "Failed workflow type",
        groupValuesLabel: "Failed workflow types",
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form: getMultiselectForm(DataStoreFields.FAILED_WORKFLOW_TYPE),
            format: values => values
        }))
    },
    [DataStoreFields.WORKFLOW_START_TIME]:                 {
        key: DataStoreFields.WORKFLOW_START_TIME,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow start time",
        groupValuesLabel: "Workflow start times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.WORKFLOW_END_TIME]:                 {
        key: DataStoreFields.WORKFLOW_END_TIME,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getDateForm(),
            match: "date"
        })),
        propertyLabel: "Workflow end time",
        groupValuesLabel: "Workflow end times",
        group: FilterPropertyGroups.TIME_FILTERS
    },
    [DataStoreFields.DAYS_SINCE_HEAD_STAGE]: {
        key: DataStoreFields.DAYS_SINCE_HEAD_STAGE,
        operators: [FilterTokenOperators.GREATER_THAN_EQUAL, FilterTokenOperators.LESS_THAN_EQUAL].map(operator => ({
            operator: operator as PropertyFilterProps.ComparisonOperator,
            form: getNonNegativeFloatForm,
        })),
        propertyLabel: "Days Since Head Stage",
        groupValuesLabel: "Days Since Head Stages",
        group: FilterPropertyGroups.TIME_FILTERS,
    },
    [DataStoreFields.PRODUCT] : {
        key: DataStoreFields.PRODUCT,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm(DataStoreFields.PRODUCT),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Capability',
        groupValuesLabel: "Capabilities"
    },
    [DataStoreFields.USE_CASE] : {
        key: DataStoreFields.USE_CASE,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm(DataStoreFields.USE_CASE),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Primitive',
        groupValuesLabel: "Primitive's"
    },
    [DataStoreFields.VARIANT] : {
        key: DataStoreFields.VARIANT,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm(DataStoreFields.SUB_PROGRAM),
                format: values => values
            }
        ],
        group: FilterPropertyGroups.MULTI_SELECT_FILTERS,
        propertyLabel: 'Variant',
        groupValuesLabel: "Variant's"
    },
    [DataStoreFields.PROGRAM] : {
        key: DataStoreFields.PROGRAM,
        propertyLabel: 'Classification Tag (Legacy program)',
        groupValuesLabel: 'Classification Tags (Legacy programs)',
        group: FilterPropertyGroups.LEGACY_MULTISELECT_FILTERS,
        operators: [FilterTokenOperators.EQUAL, FilterTokenOperators.NOT_EQUAL].map((operator) => ({
            operator: operator,
            form: getMultiselectForm(DataStoreFields.PROGRAM),
            format: values => values
        }))
    },
    [DataStoreFields.SUB_PROGRAM] : {
        key: DataStoreFields.SUB_PROGRAM,
        operators: [
            {
                operator: FilterTokenOperators.EQUAL,
                form: getMultiselectForm(DataStoreFields.SUB_PROGRAM),
                format: values => values
            },
            {
                operator: FilterTokenOperators.REGEX,
                form: getRegexForm(),
                format: value => value
            },
        ],
        group: FilterPropertyGroups.LEGACY_MULTISELECT_FILTERS,
        propertyLabel: 'Sub Classification Tag (Legacy Sub program)',
        groupValuesLabel: 'Sub Classification Tags (Legacy Sub programs)'
    },
}
