/**
 * Checks if the input string is a valid regex pattern
 */
export const isValidRegex = (pattern: string): boolean => {
    try {
        new RegExp(pattern);
        return true;
    } catch (e) {
        return false;
    }
};

/**
 * Sanitizes the input string for regex matching
 * If the input is already a valid regex contains expression and not just a plain string, returns as is
 * Otherwise, wraps it with .* to create a contains pattern
 */
export const sanitizeRegexPattern = (value: string): string => {
    if (!value || (isValidRegex(value) && /[\^\$\*\[\]\{\}\(\)\+\?\|\\\.]/.test(value))) {
        return value;
    }
    return `.*${value}.*`;
};
