import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {fixStringCasing} from "src/utils/string-helper";
import {
    RUNNING_WORKFLOW_AGE_DISTRIBUTION, RunningWorkflowSummaryNode,
    transformDaysRangeToTimestamp, transformDaysRangeToTimestampV2,
} from "src/components/sm-overview-dashboards/workflow-detail-view/workflow-detail-view.utils";
import {FilterLink} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import React from "react";
import {STATES} from "src/interfaces/workflow";
import {Badge, StatusIndicator} from "@amzn/awsui-components-react-v3";


const applyRORFilter = (item: RunningWorkflowSummaryNode) => {
    return item.isROROpen != null && item.isROROpen != undefined ?
        {[`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.IS_ROR_OPEN}`]: item.isROROpen ? "1" : "0"}
        : {}
}

const getWorkflowAgeRangeDistributionColumnConfig = (filterQueryPayload: Record<string, string>, isWorkflowTable: boolean) => {
    return Object.keys(RUNNING_WORKFLOW_AGE_DISTRIBUTION).map(workflowAge => {
        return {
            id: workflowAge,
            header : workflowAge,
            cell: (item: RunningWorkflowSummaryNode) =>
                <FilterLink
                    displayText={<DataCell item={item} workflowAge={workflowAge}/>}
                    payload={{
                        ...filterQueryPayload,
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: STATES.RUNNING,
                        ...(isWorkflowTable ? {
                                [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${DataStoreFields.WORKFLOW_START_TIME}`]:
                                    transformDaysRangeToTimestampV2(filterQueryPayload,
                                    RUNNING_WORKFLOW_AGE_DISTRIBUTION[`${workflowAge}` as keyof typeof RUNNING_WORKFLOW_AGE_DISTRIBUTION])
                            }:
                            // TODO: The stage wise aggregation of long running view isn't being used anymore. Clear this.
                            {
                                [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.HEAD_STAGE}`]: item.stage,
                                    [`${QueryVerb.MUST}::${FilterOperation.RANGE}:${DataStoreFields.DAYS_SINCE_HEAD_STAGE}`]
                        : transformDaysRangeToTimestamp(RUNNING_WORKFLOW_AGE_DISTRIBUTION[`${workflowAge}` as keyof typeof RUNNING_WORKFLOW_AGE_DISTRIBUTION]),
                        }),
                        ...(applyRORFilter(item))
                    }}/>
        }
    })
}

export const getRunningProgramSummaryColumnDefinition = (filterQuery: string, isWorkflowTable: boolean)
    : Array<TableProps.ColumnDefinition<RunningWorkflowSummaryNode>> => {
    const filterQueryPayload = JSON.parse(filterQuery);
    return [
        {
            id: 'stage',
            header:  isWorkflowTable ? 'Workflow' : 'System',
            cell: (item) => <PrimaryRowCell item={item}/>,
            minWidth: 50
        },
        {
            id: 'workflowCount',
            header: isWorkflowTable ? 'All Running Workflows' : 'All Running Systems',
            cell: (item) =>
                <FilterLink
                    displayText={item.data['total']}
                    payload={{
                        ...filterQueryPayload,
                        [`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: STATES.RUNNING,
                        ...(isWorkflowTable ? {}:
                            {[`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.HEAD_STAGE}`]: item.stage})
                        ,...(applyRORFilter(item))
                    }}/>
        }, ...getWorkflowAgeRangeDistributionColumnConfig(filterQueryPayload, isWorkflowTable)
    ]
}

export const PrimaryRowCell :({item}: { item: RunningWorkflowSummaryNode }) => JSX.Element
    = ({item}: {item: RunningWorkflowSummaryNode}) => {
    const primaryRecordText = fixStringCasing(item.nodeId)
    let primaryRowRecord: JSX.Element | string = primaryRecordText;
    if(item.parentId){
        primaryRowRecord = item.isROROpen ? <Badge color="blue">{primaryRecordText}</Badge> : <Badge>{primaryRecordText}</Badge>
    }
    return (
        <>
            {primaryRowRecord}
        </>
    )
}

export const DataCell: ({item}: { item: RunningWorkflowSummaryNode, workflowAge: string }) => JSX.Element
    = ({item, workflowAge}: {item: RunningWorkflowSummaryNode, workflowAge: string }) => {
    let cellData: JSX.Element | string = <p>{item.data[`${workflowAge}`]}</p>;
    switch (RUNNING_WORKFLOW_AGE_DISTRIBUTION[`${workflowAge}` as keyof typeof RUNNING_WORKFLOW_AGE_DISTRIBUTION]) {
        case RUNNING_WORKFLOW_AGE_DISTRIBUTION["0-10 days"] :
            cellData = <StatusIndicator  type="pending">
                <p style={{color: '#229954', display: 'inline'}}> {item.data[`${workflowAge}`]} </p>
                        </StatusIndicator>;
            break;
        case RUNNING_WORKFLOW_AGE_DISTRIBUTION["10-30 days"] :
            cellData = <StatusIndicator type="pending">
                <p style={{color: '#F1C40F', display: 'inline'}}> {item.data[`${workflowAge}`]} </p>
            </StatusIndicator>;
            break;
        case RUNNING_WORKFLOW_AGE_DISTRIBUTION["30-50 days"] :
            cellData = <StatusIndicator  type="pending">
                <p style={{color: '#D68910', display: 'inline'}}> {item.data[`${workflowAge}`]} </p>
                        </StatusIndicator>;
            break;
        case RUNNING_WORKFLOW_AGE_DISTRIBUTION["> 50 days"] :
            cellData = <StatusIndicator type="warning" colorOverride={'grey'}>
                <p style={{color: '#CB4335', display: 'inline'}}> {item.data[`${workflowAge}`]} </p>
                        </StatusIndicator>;
            break;
    }
    return <>{cellData}</>
}
