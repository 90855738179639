import {PropertyFilterOperatorForm} from "@amzn/awsui-collection-hooks";
import React from "react";
import {OptionDefinition} from "@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces";
import {useQuery} from "@tanstack/react-query";
import {LoadFieldValues} from "src/model/LoadFieldValues";
import {FormField, Multiselect} from "@amzn/awsui-components-react-v3";
import {Spinner} from "@amzn/awsui-components-react";

export const INDEX = "swojoindata_alias";

export const getMultiselectForm = (field: string): PropertyFilterOperatorForm<string> =>
    ({value, onChange}) => {
    const currentValue = value ? value.split(',') : [];
    const [selectedOptions, setSelectedOptions] = React.useState<readonly OptionDefinition[]>(
          currentValue.map((value) => ({
                value,
                label: value,
          }))
    );

    const { data: formOptions, isLoading } = useQuery({
          queryKey: ["multiselect", field, INDEX],
          queryFn: async () => {
                const loadFieldValues = new LoadFieldValues();
                const response = await loadFieldValues.loadDistinctFieldValuesFromIndex(
                    field,
                    INDEX
                );
                return response.values.map((value) => ({
                    value,
                    label: value,
                }));
          },
          staleTime: Infinity,
          cacheTime: Infinity,
    });

    return (
        <div style={{height: '250px'}}>
            {/* Note: this is a workaround (Adding a div with inline height) to add multiselect component to property filter
               component. The inherent support is still under implementation by Polaris team, till then we have used a div
               with specific height to get multiselect rendering right
            */}
            <FormField stretch>
                {isLoading ? (
                    <Spinner size="normal"/>
                ) : (
                    <Multiselect
                        data-testid="multi-select-input"
                        selectedOptions={selectedOptions}
                        onChange={({detail}) => {
                            const newValue = detail.selectedOptions.map(option => option.value).join(',');
                            onChange(newValue);
                            setSelectedOptions(detail.selectedOptions);
                        }}
                        options={formOptions}
                        filteringType="auto"
                        placeholder="Choose options"
                        tokenLimit={4}
                    />
                )}
            </FormField>
        </div>
    );
}
