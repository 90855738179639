export const formatNumericalValues = (number: number|undefined) =>
    number !== undefined && number !== null && number >= 0 ? number.toFixed(2).toString() : 'NA';


export const getTimeDiff = (diff: number, diffParam: 'DAY'|'MONTH'|'YEAR') => {
    const currentDate = new Date();
    switch (diffParam) {
        case 'DAY':
            currentDate.setDate(currentDate.getDate() - diff);
            break;
        case 'MONTH':
            currentDate.setDate(1);
            currentDate.setMonth(currentDate.getMonth() - diff);
            break;
        case 'YEAR':
            currentDate.setFullYear(currentDate.getFullYear() - diff);
            break;
    }
    currentDate.setHours(0, 0, 0, 0);
    return currentDate.getTime();
}
