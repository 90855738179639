const downloadFileFromBlobUrl = (blob: string, filename: string) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export const downloadResourceFromUrl = async (url: string, fileName: string) => {
    const resource = await fetch(url, {
        mode: 'cors'
    });

    const resourceBlob = await resource.blob();
    const blobUrl = window.URL.createObjectURL(resourceBlob);
    downloadFileFromBlobUrl(blobUrl, fileName);
}

export function downloadSampleFile(data: string, fileName: string) {
    const blob = new Blob([data], {type: "text/csv;"});
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
