export const clientId = "SwoBulkRestart";
export const recipeId = "SwoBulkRestart";
export const BULK_RESTART_CSV_MAX_ROWS = 100000;
export const program = "SwoBulkRestart";

export const SAMPLE_BULK_RESTART_RECORDS_INPUT_FILE = "sample_bulk_restart_records_input.csv";


export const BULK_RESTART_README_INSTRUCTIONS = [
    {
        title: "File Format",
        content: "Upload a CSV file containing workflow execution Ids that need to be restarted."
    },
    {
        title: "File Size",
        content: `Maximum 100k rows/records are allowed in the CSV file.`
    },
    {
        title: "Required Columns",
        content: "The CSV file must contain an 'executionId' column with valid execution Ids apart from other fields."
    },
    {
        title: "Expected Duration to Complete",
        content: "SWO will currently support 2 TPS (Transactions Per Second) as part of bulk restart effectively meaning it will take 1 second for restarting 2 workflow executions"
    },
    {
        title: "Note",
        content: "SWO currently supports only 1 bulk restart request across all users at a time and the concurrent requests will be queued and processed in first-in-first-out (FIFO) fashion."
    }
];

export const BULK_RESTART_README_COLUMN_SCHEMA = [
    {
        name: "executionId",
        required: true,
        description: "The unique identifier of the workflow execution to be restarted."
    },
    {
        name: "startRecipeStep",
        required: false,
        description: "Stage Name from where Workflow/Recipe Execution has to be Restarted (if not provided it will restart from first stage)."
    },
    {
        name: "reason",
        required: false,
        description: "Reason for restarting the workflow execution if not provided default reason (AUTOMATED_RESTART_NO_EXPLICIT_REASON)  will be added."
    },
    {
        name: "executionInput",
        required: false,
        description: " Json-serialized string representing nested Map with key being stageName and nested map having stage overridden input Attribute Name and value."
    },
];

export const BULK_RESTART_FILE_EXAMPLE = [
    {
        executionId: "aa53d1e2-7a09-36e2-8ec9-090bb83ba220",
        startRecipeStep: "",
        reason: "",
        executionInput: ""
    },
    {
        executionId: "f8c7b3a9-5e12-47d6-9f3b-2d1ae6c84f5d",
        startRecipeStep: "dtp",
        reason: "",
        executionInput: ""
    },
    {
        executionId: "1a9e4d2c-8b67-5f03-a2e8-7c3b9d0f1e6a",
        startRecipeStep: "dtp",
        reason: "find Match fixes",
        executionInput: ""
    },
    {
        executionId: "2e5a8c1f-9b3d-6e7a-4f0c-8d2b7a1e9c3d",
        startRecipeStep: "dtp",
        reason: "find Match fixes",
        executionInput: "{\"dtp\":{\"ingestionTimestamp\":1740360000000,\"waitForMatchingDays\":0}}"
    }

];
export const BULK_RESTART_OUTPUT_EXAMPLE = [
    {
        ExecutionId: "aa53d1e2-7a09-36e2-8ec9-090bb83ba220",
        FailureReason: "Action=RESTART not allowed at the given state=QUEUED for RecipeExecution=aa53d1e2-7a09-36e2-8ec9-090bb83ba220 (Service: SMWorkflowOrchestrationServiceLambda; Status Code: 400; Error Code: InvalidActionException; Request ID: c07b7fcb-ea4a-47a6-99fe-94970908cebc; Proxy: null)"
    },
    {
        ExecutionId: "917c935d-7ba1-3489-bf7e-c91f663ff4ac",
        FailureReason: "Action=RESTART not allowed at the given state=QUEUED for RecipeExecution=917c935d-7ba1-3489-bf7e-c91f663ff4ac (Service: SMWorkflowOrchestrationServiceLambda; Status Code: 400; Error Code: InvalidActionException; Request ID: fb21106e-df07-4498-9e26-5cd4aed04596; Proxy: null)"
    }
];
export const BULK_RESTART_RESULT_TEXT = {
    title: "Result",
    content: `For tracking Bulk restart request, please check the status of bulk restart tracking workflow execution, it's stage output will have following records:
1. totalRecords
2. failedRecords
3. OutputURL: A s3 Pre-signed URL containing details of workflow executions which failed to Restart`
};

export const SAMPLE_BULK_RESTART_RECORDS_INPUT = `executionId,startRecipeStep,reason,executionInput
aa53d1e2-7a09-36e2-8ec9-090bb83ba220,,,
f8c7b3a9-5e12-47d6-9f3b-2d1ae6c84f5d,dtp,,
1a9e4d2c-8b67-5f03-a2e8-7c3b9d0f1e6a,dtp,"find Match fixes",
2e5a8c1f-9b3d-6e7a-4f0c-8d2b7a1e9c3d,dtp,"find Match fixes","{\"dtp\":{\"ingestionTimestamp\":1740360000000,\"waitForMatchingDays\":0}}"`;

