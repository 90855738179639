import React, {useCallback, useMemo, useEffect} from "react";
import {
    Box,
    Button,
    FormField,
    Container,
    SpaceBetween,
    Modal,
    Header,
    Link,
} from "@amzn/awsui-components-react-v3";
import Select from "@amzn/awsui-components-react-v3/polaris/select";
import {toast} from "react-toastify";
import {useQuery} from '@tanstack/react-query';
import {unparse} from 'papaparse';
import {
    PROGRAM_SOURCE_GET_CSV_MAX_ROWS,
    SAMPLE_FETCH_RECORDS_INPUT,
    SAMPLE_FETCH_RECORDS_INPUT_FILE,
    GET_RECORDS_REQUIRED_COLUMNS,
    GET_README_INSTRUCTIONS,
    GET_FILE_EXAMPLE,
    GET_README_COLUMN_SCHEMA
} from "src/constants/program-source";
import {PageReadme} from "../common/page-readme";
import {ProgramSourceService} from "src/model/ProgramSourceService";
import {useProgramSource} from 'src/components/program-source-repository/program-source-util-hook';
import {parseCSVFile} from 'src/utils/csvUtils';


export function GetProgramSource() {
    const {
        programOptions,
        programSelectedOption,
        selectedFile,
        outputFileUrl,
        showConfirmation,
        setOutputFileUrl,
        setShowConfirmation,
        handleProgramChange,
        handleFileInput,
        handleSubmitClick,
        handleDownload,
        handleDownloadSample,
        isValid
    } = useProgramSource();

    const fetchProgramSources = useCallback(async (programName: string, sourceIds: string[]) => {
        const PS = new ProgramSourceService();
        return PS.getProgramSourcesByIdAndProgram({
            programName,
            sourceIds,
        });
    }, []);

    const {isFetching, error, refetch} = useQuery({
        queryKey: ['programSources', programSelectedOption.value, selectedFile],
        queryFn: async () => {
            try {
                const parseResult = await parseCSVFile(selectedFile!, {
                    maxRows: PROGRAM_SOURCE_GET_CSV_MAX_ROWS,
                    requiredColumns: GET_RECORDS_REQUIRED_COLUMNS
                });

                const sourceIds = parseResult.data
                    .map((row) => row.sourceIds || row.id)
                    .filter(Boolean);

                if (sourceIds.length === 0) {
                    throw new Error("No valid source IDs found in the CSV");
                }

                const response = await fetchProgramSources(programSelectedOption.value!, sourceIds);

                if (response.programSources && response.programSources.length > 0) {
                    const processedData = response.programSources.map((source) => ({
                        ...source,
                        marketplaceIds: Array.isArray(source.marketplaceIds) && source.marketplaceIds.length > 0
                            ? source.marketplaceIds.join(",")
                            : source.marketplaceIds,
                        sciProductStrategy: source.sciProductStrategy
                            ? JSON.stringify(JSON.parse(source.sciProductStrategy))
                            : source.sciProductStrategy,
                        sdoProductStrategy: source.sdoProductStrategy
                            ? JSON.stringify(JSON.parse(source.sdoProductStrategy))
                            : source.sdoProductStrategy,
                    }));

                    const csv = unparse(processedData);
                    const blob = new Blob([csv], {type: "text/csv;"});
                    const url = URL.createObjectURL(blob);
                    setOutputFileUrl(url);
                    toast.success("Fetch operation completed. You can now download the results.");
                    return processedData;
                } else {
                    toast.info("No data found for the provided source IDs.");
                    setOutputFileUrl(null);
                    return [];
                }
            } catch (error) {
                throw error;
            }
        },
        enabled: false,
        retry: false,
    });

    useEffect(() => {
        if (error) {
            toast.error(error instanceof Error ? error.message : "An error occurred during the fetch operation");
        }
    }, [error]);

    const handleConfirm = useCallback(() => {
        setShowConfirmation(false);
        refetch();
    }, [refetch, setShowConfirmation]);

    const sampleFileLink = useMemo(
        () => (
            <Link
                href={""}
                onFollow={() => handleDownloadSample(SAMPLE_FETCH_RECORDS_INPUT, SAMPLE_FETCH_RECORDS_INPUT_FILE)}
            >
                Download sample CSV file
            </Link>
        ),
        [handleDownloadSample]
    );

    return (
        <Box>
            <Container header={<Header variant="h2">Bulk Fetch Records</Header>}>
                <SpaceBetween direction="vertical" size="l">
                    <form onSubmit={(e) => e.preventDefault()} data-testid="get-record-form">
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Select Program" data-testid="get-record-program-selection-field">
                                <Select
                                    data-testid="get-record-program-selection-dropdown"
                                    selectedOption={programSelectedOption}
                                    onChange={handleProgramChange}
                                    options={programOptions}
                                    selectedAriaLabel="Selected"
                                    disabled={isFetching}
                                />
                            </FormField>
                            <FormField
                                label="Input File for Fetch (CSV)"
                                data-testid="form-field-file-input"
                                description={`Max ${PROGRAM_SOURCE_GET_CSV_MAX_ROWS} records`}
                            >
                                <input
                                    data-testid="get-record-file-input"
                                    type="file"
                                    onChange={handleFileInput}
                                    accept=".csv"
                                    disabled={isFetching}
                                />
                            </FormField>
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    variant="primary"
                                    loading={isFetching}
                                    disabled={!isValid || isFetching}
                                    onClick={handleSubmitClick}
                                    data-testid="get-record-submit-button"
                                >
                                    Submit
                                </Button>
                                <Button
                                    onClick={handleDownload}
                                    disabled={!outputFileUrl}
                                    data-testid="download-record-submit-button"
                                >
                                    Download Results
                                </Button>
                            </SpaceBetween>
                        </SpaceBetween>
                    </form>
                    <PageReadme
                        maxRows={PROGRAM_SOURCE_GET_CSV_MAX_ROWS}
                        instructions={GET_README_INSTRUCTIONS}
                        columnSchema={GET_README_COLUMN_SCHEMA}
                        fileExample={GET_FILE_EXAMPLE}
                        sampleFileLink={sampleFileLink}
                    />
                </SpaceBetween>
                <Modal
                    visible={showConfirmation}
                    onDismiss={() => setShowConfirmation(false)}
                    header="Confirm Creation"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={() => setShowConfirmation(false)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={handleConfirm}>
                                    Confirm
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    Are you sure you want to get these records?
                </Modal>
            </Container>
        </Box>
    );
}

