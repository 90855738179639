import React from 'react';
import {TableProps} from "@amzn/awsui-components-react-v3/polaris/table/interfaces";
import {
    DiveDeepLink,
    ExternalDashboardLink
} from "src/components/sm-overview-dashboards/summary-view/summary-components";
import {StatusIndicator, Link} from "@amzn/awsui-components-react-v3";
import {SummaryData} from "src/interfaces/SMSummaryInterface";
import {
    EXCLUDE_CYCLE_TIME_DATA_FOR_PROGRAMS,
    PROGRAM_BUSINESS_NAME
} from "src/constants/sm-overview-dashboard-constants";
import {formatNumericalValues} from "src/utils/sm-overview-dashboard-utils";
import {PROGRAM_NAMES_ENUM} from "src/constants/workflow-instance";
import {HeaderCell} from "src/components/sm-overview-dashboards/common/HeaderCellWrapper";
import {FormulaSheetExternalLink} from "src/components/sm-overview-dashboards/common/formula-sheet-external-link";
import {AppRoutes} from "src/components/common/router-common";
import {ExternalRoutes} from "src/components/common/router-common";
import {
    mergeFiltersToFilterQuery
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filters.utils";
import {
    DataStoreFields,
    FilterOperation,
    QueryVerb
} from "src/components/sm-overview-dashboards/workflow-list-view/filters/filter-constants";
import {STATES} from "src/interfaces/workflow";

export const programSummaryColumnDefinitions = (filterQuery: string, aggregationKey: string): Array<TableProps.ColumnDefinition<SummaryData>> => ([
    {
        id: 'program',
        header: 'Program',
        cell: (item) => <>{PROGRAM_BUSINESS_NAME[item.program as PROGRAM_NAMES_ENUM] || item.program}</>,
        minWidth: 100,
    }, ...summaryColumnsDefinitions(filterQuery, aggregationKey)
])

export const productSummaryColumnDefinitions = (filterQuery: string, aggregationKey: string): Array<TableProps.ColumnDefinition<SummaryData>> => ([
    {
        id: 'Capability',
        header: 'Capability',
        cell: (item) => <>{item.aggregationKey}</>,
        minWidth: 100,
    }, ...summaryColumnsDefinitions(filterQuery, aggregationKey)
])

const summaryColumnsDefinitions = (filterQuery: string, aggregationKey: string): Array<TableProps.ColumnDefinition<SummaryData>> => ([
    {
        id: 'onboardedWorkflowInstances',
        header: <HeaderCell
            text={'Workflows Onboarded'}
            columnType={'Workflow Summary'}
            description={'Metric representing total no of workflow instances onboarded.'}/>,
        cell: (item) => item.onboardedWorkflowInstances,
    },
    {
        id: 'runningWorkflowInstances',
        header: <HeaderCell
            text={'Workflows Running'}
            columnType={'Workflow Summary'}
            description={<>
                <p>Metric representing total no of workflow instances Running.</p>
                <br />
                <p>Click to see current running system for workflows and their respective age.</p>
            </>}/>,
        cell: (item) =>
            <DiveDeepLink
                displayNode={
                    <StatusIndicator type={'pending'}><p style={{color: '#D68910', display: 'inline'}}>{item.runningWorkflowInstances}</p></StatusIndicator>
                }
                aggregateKey={aggregationKey}
                aggregateKeyValue={item.program ? item.program : item.aggregationKey}
                filterQuery={filterQuery}
                route={AppRoutes.programSummaryDetailRunning}
            />
    },
    {
        id: 'failedWorkflowInstances',
        header: <HeaderCell
            text={'Workflows Failed'}
            columnType={'Workflow Summary'}
            description={<>
                <p>Metric representing total no of workflow instances failed.</p>
                <br />
                <p>Click to see system where the workflow is failed and the time since failed</p>
            </>}/>,
        cell: (item) =>
            <DiveDeepLink
                displayNode={
                    <StatusIndicator type="error" colorOverride={'red'}>{item.failedWorkflowInstances}</StatusIndicator>
                }
                aggregateKey={aggregationKey}
                aggregateKeyValue={item.program ? item.program : item.aggregationKey}
                filterQuery={filterQuery}
                route={AppRoutes.programSummaryDetailFailure}
            />
    },
    {
        id: 'stoppedWorkflowInstances',
        header: <HeaderCell
            text={'Workflows Stopped'}
            columnType={'Workflow Summary'}
            description={'Metric representing total no of workflows stopped.'}/>,
        cell: (item) => <StatusIndicator type="stopped"> {item.stoppedWorkflowInstances} </StatusIndicator>
    },
    {
        id: 'successfulWorkflowInstances',
        header: <HeaderCell
            text={'Workflows Completed'}
            columnType={'Workflow Summary'}
            description={'Metric representing total no of workflows successfully completed '}/>,
        cell: (item) =>
            <DiveDeepLink
                displayNode={
                    <StatusIndicator>{item.sucessfullWorkflowInstances}</StatusIndicator>
                }
                aggregateKey={aggregationKey}
                aggregateKeyValue={item.program ? item.program : item.aggregationKey}
                filterQuery={mergeFiltersToFilterQuery(filterQuery, {[`${QueryVerb.MUST}::${FilterOperation.MATCH}:${DataStoreFields.WORKFLOW_STATUS}`]: STATES.SUCCEEDED})}
                route={AppRoutes.workflowList}
            />
    },
    {
        id: 'avgWorkItems',
        header: <HeaderCell
            text={'Avg. workitems per run'}
            columnType={'Workitem & Failure Summary'}
            description={
                <>
                    <p>Ratio reporting average no. of workitems raised per successful workflow run attempts.</p>
                    <br />
                    <FormulaSheetExternalLink />
                </>
            }
        />,
        cell: (item) => formatNumericalValues(item.avgWorkItems)
    },
    {
        id: 'avgFailures',
        header: <HeaderCell
            text={'Avg. failures per run'}
            columnType={'Workitem & Failure Summary'}
            description={<>
                <p>Ratio reporting average no of failures per successful workflow run attempts.</p>
                <br />
                <p>Click to see failure analysis.</p>
                <br />
                <FormulaSheetExternalLink />
            </>}/>,
        cell: (item) => <ExternalDashboardLink
            displayNode={formatNumericalValues(item.avgFailures)}
            externalLink={ExternalRoutes.failureAnalysis}/>
    },
    {
        id: 'humanErrors',
        header: <HeaderCell
            text={'Avg. human errors per run'}
            columnType={'Workitem & Failure Summary'}
            description={
                <>
                    <p>Ratio reporting average no of APP Errors per successful workflow run attempts.</p>
                    <br />
                    <FormulaSheetExternalLink />
                </>}
        />,
        cell: (item) => formatNumericalValues(item.humanErrors)
    },
    {
        id: 'systemFailures',
        header: <HeaderCell
            text={'Avg. System Failures per run'}
            columnType={'Workitem & Failure Summary'}
            description={
                <>
                    <p>Ratio reporting average no of system failures reported per successful workflow run attempts.</p>
                    <br />
                    <FormulaSheetExternalLink />
                </>
            }/>,
        cell: (item) => formatNumericalValues(item.systemFailures)
    },
    {
        id: 'tpninetyWorkflowCT',
        header: <HeaderCell
            text={'TP 90 E2E Workflow Cycle Time'}
            columnType={'Cycle time Summary'}
            description={
                <>
                    <p>No days taken by 90 percentile of worklfow runs to complete.</p>
                    <br />
                    <p>Click to see Cycle time analysis.</p>
                    <br />
                    <FormulaSheetExternalLink />
                </>
            }/>,
        cell: (item) => EXCLUDE_CYCLE_TIME_DATA_FOR_PROGRAMS.includes(item.program as PROGRAM_NAMES_ENUM) ? 'NA' : <ExternalDashboardLink
            displayNode={formatNumericalValues(item.tpninetyProgramCT)}
            externalLink={ExternalRoutes.workflowCycleTimeDeepDive}
        />
    },
    {
        id: 'tpninetyworkItemresolutionCT',
        header: <HeaderCell
            text={'TP 90 WorkItem resolution cycle time'}
            columnType={'Cycle time Summary'}
            description={
                <>
                    <p>No of days taken to resolve 90th percentile of workitems created</p>
                    <br />
                    <p>Click to view RoR Deep Dive data.</p>
                    <FormulaSheetExternalLink />
                </>
            }/>,
        cell: (item) => <ExternalDashboardLink
            displayNode={formatNumericalValues(item.tpninetyworkItemresolutionCT)}
            externalLink={ExternalRoutes.rorDeepDiveWikiDashboard}
        />
    }
])
