import { STAGES } from "src/utils/app-constants";
import AWS from "aws-sdk";
import {CREATE, UPDATE} from "src/constants/program-source";

interface S3PathDetail {
    bucket: string;
    key: string;
}

export const bulkStartWorkflowInstanceS3Beta: S3PathDetail = {
    bucket: "bulk-start-inputs-beta",
    key: "bulkRequests/",
};

export const bulkStartWorkflowInstanceS3Prod: S3PathDetail = {
    bucket: "bulk-start-inputs-prod",
    key: "bulkRequests/",
};

// Get Bulk Start workflow instance s3 details
export function bulkStartWorkflowInstanceS3Details(stage: string): S3PathDetail {
    let bulkStartWorkflowInstanceS3Config;

    switch (stage) {
        case STAGES.PROD.toLowerCase():
            bulkStartWorkflowInstanceS3Config = bulkStartWorkflowInstanceS3Prod;
            break;
        default:
            bulkStartWorkflowInstanceS3Config = bulkStartWorkflowInstanceS3Beta;
    }
    return bulkStartWorkflowInstanceS3Config;
}

export const bulkRestartWorkflowInstanceS3Beta: S3PathDetail = {
    bucket: "swo-bulk-action-beta",
    key: "bulkRestarts/",
};

export const bulkRestartWorkflowInstanceS3Prod: S3PathDetail = {
    bucket: "swo-bulk-action-prod",
    key: "bulkRestarts/",
};

export function bulkRestartWorkflowInstanceS3Details(stage: string): S3PathDetail {
    let bulkStartWorkflowInstanceS3Config;

    switch (stage) {
        case STAGES.PROD.toLowerCase():
            bulkStartWorkflowInstanceS3Config = bulkRestartWorkflowInstanceS3Prod;
            break;
        case STAGES.ALPHA.toLowerCase():
            bulkStartWorkflowInstanceS3Config = bulkRestartWorkflowInstanceS3Beta;
            break;
        case STAGES.GAMMA.toLowerCase():
            bulkStartWorkflowInstanceS3Config = bulkRestartWorkflowInstanceS3Prod;
            break;
        default:
            bulkStartWorkflowInstanceS3Config = bulkRestartWorkflowInstanceS3Beta;
    }
    return bulkStartWorkflowInstanceS3Config;
}

const ProgramSourceS3Config = (stage: string, operation: string): S3PathDetail => ({
    bucket: `program-source-ui-helper-${stage.toLowerCase()}`,
    key: `program-source-${operation}-action/`,
});

export function programSourceS3Details(stage: string, operation: typeof CREATE | typeof UPDATE): S3PathDetail {
    const stageConfigs = {
        [STAGES.ALPHA.toLowerCase()]: {
            create: ProgramSourceS3Config(STAGES.ALPHA, CREATE),
            update: ProgramSourceS3Config(STAGES.ALPHA, UPDATE),
        },
        [STAGES.BETA.toLowerCase()]: {
            create: ProgramSourceS3Config(STAGES.BETA, CREATE),
            update: ProgramSourceS3Config(STAGES.BETA, UPDATE),
        },
        [STAGES.GAMMA.toLowerCase()]: {
            create: ProgramSourceS3Config(STAGES.GAMMA, CREATE),
            update: ProgramSourceS3Config(STAGES.GAMMA, UPDATE),
        },
        [STAGES.PROD.toLowerCase()]: {
            create: ProgramSourceS3Config(STAGES.PROD, CREATE),
            update: ProgramSourceS3Config(STAGES.PROD, UPDATE),
        },
    };

    return stageConfigs[stage.toLowerCase()]?.[operation] || stageConfigs[STAGES.BETA.toLowerCase()][operation];
}

export const getDownloadUrl = (bucket: string, filePath: string, fileNameMatch?: string): Promise<string> => {
    // TODO: Remove this region hardcoding once we start to get bucket region as metadata
    const s3Service = new AWS.S3({ region: "us-east-1" });
    return new Promise((resolve, reject) => {
        s3Service.listObjectsV2({ Prefix: filePath, Bucket: bucket }, (err, data) => {
            if (err) {
                reject(err.message);
            }
            const dataKey =
                fileNameMatch && data.Contents
                    ? data.Contents.find((listItem) => listItem.Key?.includes(fileNameMatch))
                    : data.Contents?.[0];
            dataKey &&
            s3Service.getSignedUrl(
                "getObject",
                {
                    Bucket: bucket,
                    Key: dataKey.Key,
                    Expires: 600,
                },
                (error, url) => {
                    if (error) {
                        reject(error.message);
                    }
                    resolve(url);
                }
            );
        });
    });
};
