import {parse, ParseResult} from 'papaparse';
import {
    GET_RECORDS_REQUIRED_COLUMNS,
    CREATE_RECORDS_REQUIRED_COLUMNS
} from "src/constants/program-source";

type GetRecordsColumns = typeof GET_RECORDS_REQUIRED_COLUMNS[number];
type CreateRecordsColumns = typeof CREATE_RECORDS_REQUIRED_COLUMNS[number];
type RequiredColumns = GetRecordsColumns | CreateRecordsColumns;

interface ParseCSVOptions {
    maxRows: number;
    requiredColumns?: readonly RequiredColumns[];
}

export const parseCSVFile = async (file: Blob, options: ParseCSVOptions): Promise<ParseResult<any>> => {
    return new Promise((resolve, reject) => {
        parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                if (result.data.length === 0) {
                    reject(new Error("CSV File should have at least one row"));
                    return;
                }

                if (result.data.length >= options.maxRows + 1) {
                    reject(new Error(`Max ${options.maxRows} rows allowed`));
                    return;
                }

                if (options.requiredColumns) {
                    const missingColumns = options.requiredColumns.filter(
                        col => !result.meta.fields?.includes(col)
                    );
                    if (missingColumns.length > 0) {
                        reject(new Error(`Missing required columns: ${missingColumns.join(', ')}`));
                        return;
                    }
                }
                resolve(result);
            },
            error: reject,
        });
    });
};